.l-text-x-limit() {
	margin-left: auto;
	margin-right: auto;
	width: @l-text--pct--lg;

	.mq--xs({
		width: @l-text--pct--xs;
	});

	.mq--sm({
		width: @l-text--pct--sm;
	});

	.mq--md({
		width: @l-text--pct--md;
	});

	.mq--xl({
		width: @l-text--pct--xl;
	});
}

.l-media-x-limit() {
	.mq--xs({
		width: @l-media--pct--xs;
	});

	.mq--sm({
		width: @l-media--pct--sm;
	});

	.mq--md({
		width: @l-media--pct--md;
	});

	.mq--lg({
		width: @l-media--pct--lg;
	});

	.mq--xl({
		width: @l-media--pct--xl;
	});
}

.l-media-grid-x-limit() {
	.mq--xs({
		width: @l-text--pct--xs;
	});

	.mq--sm({
		width: @l-text--pct--sm;
	});

	.mq--md({
		width: @l-text--pct--md;
	});

	.mq--lg({
		width: @l-text--pct--lg;
	});

	.mq--xl({
		width: @l-text--pct--xl;
	});
}

.l-carousel-x-limit() {
	margin-left: auto;
	margin-right: 0;

	// If the carousel is a type of breakout, lets no style it up
	.content-item:not(.content-item--breakout) & {
		margin-right: -@l-page-body--outdent--pct--lg;
		width: @l-carousel-limit--pct--lg;

		.mq--xs({
			margin-right: -@l-page-body--outdent--pct--xs;
			width: @l-carousel-limit--pct--xs;
		});

		.mq--sm({
			margin-right: -@l-page-body--outdent--pct--sm;
			width: @l-carousel-limit--pct--sm;
			content: @l-page-body--outdent--col--sm, @l-page-body--content-box--col--sm;
		});

		.mq--md({
			margin-right: -@l-page-body--outdent--pct--md;
			width: @l-carousel-limit--pct--md;
		});

		.mq--xl({
			.page-section.page-layout--has-no-aside & {
				margin-right: -@l-page-body--outdent--pct--xl;
				width: @l-carousel-limit--pct--xl;
			}

			.page-section.page-layout--has-aside & {
				margin-right: -@l-page-body--outdent-w--aside--pct--xl;
				width: @l-carousel-limit--pct--xl--aside;
			}
		});

		.media-carousel__items,
		.media-carousel__footer {
			width: @l-carousel-items--pct--lg;

			.mq--xs({
				width: @l-carousel-items--pct--xs;
			});

			.mq--sm({
				width: @l-carousel-items--pct--sm;
			});

			.mq--md({
				width: @l-carousel-items--pct--md;
			});

			.mq--xl({
				width: @l-carousel-items--pct--xl;

				.page-section.page-layout--has-aside & {
					width: @l-carousel-items--pct--xl--aside;
				}
			});
		}
	}

	.content-item:not(.content-item--breakout) .media-carousel[data-aspect-ratio="3:2"] & {
		.media-carousel__items {
			padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--lg);

			.mq--xs({
				padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--xs);
			});

			.mq--sm({
				padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--sm);
			});

			.mq--md({
				padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--md);
			});

			.mq--xl({
				.page-section.page-layout--has-no-aside & {
					padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--xl);
				}

				.page-section.page-layout--has-aside & {
					padding-top: (@l-carousel--aspect-ratio * @l-carousel-items--pct--xl--aside);
				}
			});
		}
	}
}
