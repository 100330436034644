.content-item--text-box-group {
	&.is-numbered {
		.content-text-box {
			counter-increment: content-text-box__index;
		}
	}
}

.content-block-group {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.content-text-box {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-basis: 100%;
	flex-grow: 1;

	.mq--xs({
		width: auto !important; // HACK!
	});
}

.content-text-box__body {
	.t-fout-mask();
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.mq--xs({
		width: 75%;
	});
}

.content-text-box__body-prop {
	flex: 1 0 auto;

	&:after {
		content: "";
		display: block;
	}

	.mq--xs({
		width: 25%;

		&:after {
			padding-top: 200%;
		}
	});
}

@title-font-size--base: 1rem;
@title-font-size--xs: 1.125rem;
@title-font-size--sm: unit(4 / 3, rem);
@title-font-size--lg: 1.5rem;
@title-line-height--base: 1.25;
@index-font-size--base: 0.75rem;
@index-line-height--base: unit(@title-line-height--base / @index-font-size--base); // 1.25 = default .content-text-box__title line-height

.content-text-box__title {
	margin: 0.5em 0 0 0;
	font-family: @font-family-secondary;
	font-size: @title-font-size--base;
	line-height: @title-line-height--base;
	font-weight: normal;
	color: @color-heading;

	a {
		color: currentColor;
		.t-link-underline-scaffolding();
	}

	&:after {
		position: absolute;
		padding-left: @l-site--col-gutter;
		padding-right: @l-site--col-gutter;
		font-family: @font-family-secondary;
		font-size: @index-font-size--base;
		line-height: @index-line-height--base;
		font-weight: normal;

		.content-item--text-box-group.is-numbered & {
			content: counter(content-text-box__index, decimal-leading-zero);
		}
	}

	.mq--xs({
		font-size: @title-font-size--xs !important; // HACK

		&:after {
			left: 100%;
			line-height: unit(@title-font-size--xs * @title-line-height--base / @index-font-size--base) !important; // HACK
		}
	});

	.mq--sm--up({
		&:after {
			right: -100%;
		}
	});

	.mq--sm--up--md({
		font-size: @title-font-size--sm;

		&:after {
			line-height: unit(@title-font-size--sm * @title-line-height--base / @index-font-size--base);
		}
	});
}

@description-font-size--base: 0.9375rem;
.content-text-box__description {
	margin-top: 0.25em;
	margin-bottom: -0.25em;
	font-size: @description-font-size--base;

	// HACK overriding
	line-height: inherit !important;

	.mq--xs({
		font-size: 0.75rem !important; // HACK
	});

	.mq--xl({
		font-size: (@description-font-size--base / @font-size--factor--xl);
	});
}

.content-text-box__title,
.content-text-box__description {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
}

.content-text-box--theme-default {
	background-color: @theme-default--bg;
}

.content-text-box--theme-custom {
	.content-text-box__title,
	.content-text-box__description {
		color: currentColor;
	}
}

// ============================================================================
// Mixin
.indent-sizing(@col-count, @content-col-total) {
	.content-text-box {
		padding-left: percentage(@col-count / @content-col-total);
		padding-right: percentage(@col-count / @content-col-total);
	}

	.content-text-box__body {
		@col-offset-count: (@content-col-total - (@col-count * 2));
		padding-right: percentage(0.5 / @col-offset-count);
	}
}


// Single Item
.content-item--text-box-group {
	&.is-single-item {
		.mq--sm({
			.indent-sizing(@l-text--margin-x--col--sm, @l-page-body--content-box--col--sm);
		});

		.mq--md({
			.indent-sizing(@l-text--margin-x--col--md, @l-page-body--content-box--col--md);
		});

		.mq--lg({
			.indent-sizing(@l-text--margin-x--col--lg, @l-page-body--content-box--col--lg);
		});

		.mq--xl({
			.indent-sizing(@l-text--margin-x--col--xl, @l-page-body--content-box--col--xl);
		});

		.content-text-box__body {
			.mq--sm--up({
				flex-basis: 50%;
			});
		}

		// Give us some proportions!
		.content-text-box__body-prop {
			.mq--sm--up({
				&:after {
					padding-top: 100%;
				}
			});
		}

		.content-text-box__title {
			.mq--lg({
				font-size: @title-font-size--lg;

				&:after {
					line-height: unit(@title-font-size--lg * @title-line-height--base / @index-font-size--base);
				}
			});

			.mq--xl({
				font-size: (@title-font-size--lg / @font-size--factor--xl);

				&:after {
					line-height: unit(@title-font-size--lg / @font-size--factor--xl * @title-line-height--base / @index-font-size--base);
				}
			});
		}
	}
}

// Multiple Item
.content-item--text-box-group {
	&.is-multiple-item {
		.content-text-box__title {
			.mq--md--up({
				position: relative;

				&:after {
					right: 0;
				}
			});
		}

		.mq--sm({
			.indent-sizing(@l-text--margin-x--col--sm, @l-page-body--content-box--col--sm);
		});

		// Main layout
		.content-text-box {
			&:nth-child(odd),
			&:nth-child(even) {
				.mq--sm({
					.content-text-box__body {
						flex-basis: 50%;
					}

					.content-text-box__body-prop {
						&:after {
							padding-top: 100%;
						}
					}
				});

				.mq--md--up({
					flex-basis: 50%;

					.content-text-box__body {
						margin-right: -25%;
						flex-basis: 100%;
					}

					.content-text-box__body-prop {
						flex-basis: 25%;

						&:after {
							padding-top: 300%;
						}
					}
				});

				.content-text-box__title {
					.mq--lg({
						font-size: @title-font-size--lg;

						&:after {
							line-height: unit(@title-font-size--lg * @title-line-height--base / @index-font-size--base);
						}
					});

					.mq--xl({
						font-size: (@title-font-size--lg / @font-size--factor--xl);

						&:after {
							line-height: unit(@title-font-size--lg / @font-size--factor--xl * @title-line-height--base / @index-font-size--base);
						}
					});
				}
			}

			&:nth-child(odd) {
				.content-text-box__body {
					.mq--md({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--md / 2));
						padding-left: percentage(1 / (@l-page-body--content-box--col--md / 2));
					});

					.mq--lg({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--lg / 2));
						padding-left: percentage(1 / (@l-page-body--content-box--col--lg / 2));
					});

					.mq--xl({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--xl / 2));
						padding-left: percentage(1.5 / (@l-page-body--content-box--col--xl / 2));
					});
				}
			}

			&:nth-child(even) {
				.content-text-box__body {
					.mq--md({
						padding-right: percentage(1 / (@l-page-body--content-box--col--md / 2));
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--md / 2));
					});

					.mq--lg({
						padding-right: percentage(1 / (@l-page-body--content-box--col--lg / 2));
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--lg / 2));
					});

					.mq--xl({
						padding-right: percentage(1.5 / (@l-page-body--content-box--col--xl / 2));
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--xl / 2));
					});
				}
			}
		}
	}
}
