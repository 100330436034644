._lock-document-scrolling {
	overflow: hidden !important;
	height: 100% !important;

	body {
		position: relative; // iOS wants this
		overflow: hidden !important;
		height: 100% !important
	}

	iframe[src^="https"],
	iframe[src^="http"] {
		// Hide nested (external!?) iframes from grabbing focus
		// We need inert
		// https://github.com/WICG/inert
		visibility: hidden;
	}
}

._lock-scrolling {
	overflow: hidden !important;
	height: 100% !important;
}
