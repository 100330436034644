.media-figure {
	margin: 0 auto;
}

.media-figure--image {
	img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}

.media-figure--video {
	.media-figure__body {
		position: relative;
		padding-top: (9 / 16 * 100%);

		> * {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.media-figure--statistic {
	.media-figure__body {
		padding: 2.5em (4em / 3);
		text-align: center;
	}

	.media-figure--statistic-theme(@theme-default--bg--dark, @color-heading, @color-body);
}

.media-figure--statistic__title {
	@font-size--base: 1.75rem;
	margin: 0;
	font-family: @font-family-secondary;
	font-weight: normal;
	font-size: @font-size--base;
	line-height: (4 / 3);
	color: @color-heading;

	.mq--sm--down({
		font-size: (1.5rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});
}

.media-figure--statistic__definition {
	@font-size--base: 0.8125rem;
	margin-top: 0.5em;
	margin-bottom: 0;
	font-size: @font-size--base;

	.mq--sm--down({
		font-size: (0.75rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}
