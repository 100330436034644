.content-item--breakout {
	clear: none;
	box-sizing: content-box;
	margin-bottom: (@l-content-item--margin-y--lg * 0.375);

	+ .content-item.content-item--text {
		// When we’re the first content-item of a page section, the following text content item should have it’s vertical margin reduced
		.page-section__header + & {
			margin-top: 0;
		}

		.content-item__title {
			text-align: left;
		}
	}

	.page-section__header + & + .content-item.content-item--text {

	}

	.mq--sm--down({
		margin-bottom: (@l-content-item--margin-y--sm * 0.5);
	});

	+ .content-item--text,
	+ .content-item--text-small {
		clear: none;

		&.content-item--text--has-no-title {
			margin-top: 0;
		}
	}

	// Restore default box-sizing
	> * {
		box-sizing: border-box;
	}

	// Media
	&.content-item--media {
		.content-item__media-limit {
			width: auto;
		}

		.media-figure {
			display: block; // overwrite inline-block styling (.media-figure--image)
			margin-left: 0;
			margin-right: 0;
		}

		.media-figure__caption {
			max-width: none;
		}
	}

	// Carousel
	&.content-item--carousel {
		.media-carousel[data-aspect-ratio="3:2"] {
			.media-carousel__items {
				padding-top: (2 / 3 * 100%);
			}
		}

		.flickity-viewport {
			overflow: hidden;
		}

		.media-carousel__items {
			width: 100%;
		}

		.content-item__carousel-limit {
			overflow: inherit;
			width: auto;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.content-item--quotation {
		margin-bottom: @l-content-item--margin-y--lg;

		.mq--sm--down({
			margin-bottom: @l-content-item--margin-y--sm ;
		});

		.content-item--quotation__limit {
			width: auto;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.content-item--breakout--right {
	// clear: left;
	float: right;

	.content-item--breakout__inner {
		margin-left: 0;
	}

	.content-item__media-limit {
		.content-item--breakout__caption {
			margin-left: 0;
		}
	}
}

.content-item--breakout--½ {
	width: 50%;

	&.content-item--breakout--right {
		padding-left: @l-content-breakout--gutter--center--pct--lg;

		.mq--xs({
			padding-left: @l-content-breakout--gutter--center--pct--xs;
		});

		.mq--sm({
			padding-left: @l-content-breakout--gutter--center--pct--sm;
		});

		.mq--md({
			padding-left: @l-content-breakout--gutter--center--pct--md;
		});

		.mq--xl({
			padding-left: @l-content-breakout--gutter--center--pct--xl;
		});

		&.content-item--quotation {
			// More classes for specificity
			&.content-item--breakout--small,
			&.content-item--breakout--medium,
			&.content-item--breakout--large {
				.content-item--breakout__inner {
					width: @l-content-breakout--small--pct--lg;
					padding-left: @l-content-breakout--gutter--center--nested--pct--lg;
					padding-right: @l-content-breakout--gutter--center--nested--pct--lg;

					.mq--xs({
						width: @l-content-breakout--small--pct--xs;
						padding-left: @l-content-breakout--gutter--center--nested--pct--xs;
						padding-right: @l-content-breakout--gutter--center--nested--pct--xs;
					});

					.mq--sm({
						width: @l-content-breakout--small--pct--sm;
						padding-left: @l-content-breakout--gutter--center--nested--pct--sm;
						padding-right: @l-content-breakout--gutter--center--nested--pct--sm;
					});

					.mq--md({
						width: @l-content-breakout--small--pct--md;
						padding-left: @l-content-breakout--gutter--center--nested--pct--md;
						padding-right: @l-content-breakout--gutter--center--nested--pct--md;
					});

					.mq--xl({
						padding-left: @l-content-breakout--gutter--center--nested--pct--xl;
					});
				}
			}
		}
	}

	// ½ Limit Small
	&.content-item--breakout--small {
		&.content-item--breakout--right {
			.content-item--breakout__inner {
				width: @l-content-breakout--small--pct--lg;
				padding-left: @l-content-breakout--gutter--center--nested--pct--lg;

				.content-item--quotation& {
					padding-right: @l-content-breakout--gutter--center--nested--pct--lg;
				}

				.content-item--breakout__caption {
					margin-right: 0;
				}

				.mq--xs({
					width: @l-content-breakout--small--pct--xs;
					padding-left: @l-content-breakout--gutter--center--nested--pct--xs;

					.content-item--breakout__caption {
						margin-right: (@l-page-body--outdent--col--xs / @l-content-breakout--small--content-box--col--xs * 100%);
					}
				});

				.mq--sm({
					width: @l-content-breakout--small--pct--sm;
					padding-left: @l-content-breakout--gutter--center--nested--pct--sm;
				});

				.mq--md({
					width: @l-content-breakout--small--pct--md;
					padding-left: @l-content-breakout--gutter--center--nested--pct--md;
				});

				.mq--xl({
					width: @l-content-breakout--small--pct--xl;
					padding-left: @l-content-breakout--gutter--center--nested--pct--xl;

					.content-item--quotation& {
						padding-right: @l-content-breakout--gutter--center--nested--pct--xl;
					}
				});
			}
		}
	}

	// ½ Limit Medium
	&.content-item--breakout--medium {
		&.content-item--breakout--right {
			.content-item--breakout__inner {
				width: @l-content-breakout--medium--pct--lg;
				padding-left: @l-content-breakout--gutter--center--nested--pct--lg;

				.content-item--breakout__caption {
					margin-right: (@l-text--margin-x--col--lg / @l-content-breakout--medium--content-box--col--lg * 100%);
				}

				.mq--xs({
					width: @l-content-breakout--medium--pct--xs;
					padding-left: @l-content-breakout--gutter--center--nested--pct--xs;

					.content-item--breakout__caption {
						margin-right: (@l-page-body--outdent--col--xs / @l-content-breakout--medium--content-box--col--xs * 100%);
					}
				});

				.mq--sm({
					width: @l-content-breakout--medium--pct--sm;
					padding-left: @l-content-breakout--gutter--center--nested--pct--sm;

					.content-item--breakout__caption {
						margin-right: (@l-text--margin-x--col--sm / @l-content-breakout--medium--content-box--col--sm * 100%);
					}
				});

				.mq--md({
					width: @l-content-breakout--medium--pct--md;
					padding-left: @l-content-breakout--gutter--center--nested--pct--md;

					.content-item--breakout__caption {
						margin-right: (@l-text--margin-x--col--md / @l-content-breakout--medium--content-box--col--md * 100%);
					}
				});

				.mq--xl({
					width: @l-content-breakout--medium--pct--xl;
					padding-left: @l-content-breakout--gutter--center--nested--pct--xl;

					.content-item--quotation& {
						width: @l-content-breakout--medium--pct--xl;

						.page-layout--has-aside & {
							padding-right: (@l-content-breakout--gutter--center--nested--pct--xl * 1.5);
						}

						.page-layout--has-no-aside & {
							padding-right: 0;
						}
					}

					.content-item--breakout__caption {
						margin-right: (@l-text--margin-x--col--xl / @l-content-breakout--medium--content-box--col--xl * 100%);
					}
				});
			}
		}
	}

	// ½ Limit Large
	&.content-item--breakout--large {
		&.content-item--breakout--right {
			.content-item--breakout__inner {
				width: @l-content-breakout--large--pct--lg;
				padding-left: @l-content-breakout--gutter--center--nested--pct--lg;

				.content-item--breakout__caption {
					margin-right: ((@l-page-body--outdent--col--lg + @l-content-breakout--gutter--center--col--lg) / @l-content-breakout--large--content-box--col--lg * 100%);
				}

				.mq--xs({
					width: @l-content-breakout--large--pct--xs;
					padding-left: @l-content-breakout--gutter--center--nested--pct--xs;

					.content-item--breakout__caption {
						margin-right: (@l-page-body--outdent--col--xs / @l-content-breakout--large--content-box--col--xs * 100%);
					}
				});

				.mq--sm({
					width: @l-content-breakout--large--pct--sm;
					padding-left: @l-content-breakout--gutter--center--nested--pct--sm;

					.content-item--breakout__caption {
						margin-right: ((@l-page-body--outdent--col--sm + @l-text--margin-x--col--sm) / @l-content-breakout--large--content-box--col--sm * 100%);
					}
				});

				.mq--md({
					width: @l-content-breakout--large--pct--md;
					padding-left: @l-content-breakout--gutter--center--nested--pct--md;

					.content-item--breakout__caption {
						margin-right: ((@l-page-body--outdent--col--md + @l-text--margin-x--col--md) / @l-content-breakout--large--content-box--col--md * 100%);
					}
				});

				.mq--xl({
					.page-article--has-no-aside & {
						width: @l-content-breakout--large--pct--xl;
						padding-left: @l-content-breakout--gutter--center--nested--pct--xl;

						.content-item--breakout__caption {
							margin-right: ((@l-page-body--outdent--col--xl - @l-content-breakout--gutter--center--col--lg) / @l-content-breakout--large--content-box--col--xl * 100%);
						}
					}

					.page-article--has-aside & {
						width: @l-content-breakout--large--pct--xl--aside;
						padding-left: @l-content-breakout--gutter--center--nested--pct--xl;

						.content-item--breakout__caption {
							margin-right: ((@l-page-body--w-aside--outdent-w--col--xl + @l-content-breakout--gutter--center--col--lg) / @l-content-breakout--large--content-box--col--xl--aside * 100%);
						}
					}

					.content-item--quotation& {
						.page-layout--has-aside & {
							// Identical to `.content-item--breakout--medium`
							width: @l-content-breakout--medium--pct--xl;
							padding-right: (@l-content-breakout--gutter--center--nested--pct--xl * 1.5);
						}

						.page-layout--has-no-aside & {
							padding-right: ((@l-page-body--outdent--col--xl - (@l-page-section--outdent--col--xl * 0.5)) / (@l-page-body--content-box--col--xl / 2) * 100%);
						}
					}
				});
			}
		}
	}
}
