.content-item--etapestry-form {
	.etapestry-form {
		margin: 0 -1em;
		border: none;//1px solid @theme-default--bg--dark;
		border-radius: @border-radius;

		.mq--sm--down({
			margin: 0 -0.5rem;
		});
	}
}
