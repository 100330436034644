.call-to-action {
	@font-size--base: 0.625rem;
	display: inline-block;
	padding: 1em 1.5em;
	border: 1px solid;
	font-size: @font-size--base;
	line-height: 1.8;
	font-family: @font-family-secondary;
	letter-spacing: 0.25em;
	text-transform: uppercase;
	border-radius: 0.125rem;

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	// Default colouring
	.content-item--text &,
	.content-item--text-small & {
		.call-to-action--theme(@palette-secondary-light-green);
		margin: 0.75rem @l-site--col-gutter 1rem;
	}
}

.call-to-action__label {
	.t-fout-mask();
}

.call-to-action--theme-custom(inverse-white, #fff);
