html {
	background-color: @palette-primary-orange;
	height: 100%;
	cursor: default;

	.mq--sm--down({
		font-size: @font-size-base--sm;
	});

	.mq--xl({
		font-size: @font-size-root--xl;
	});
}

body {
	min-height: 100%;
}

// http://stackoverflow.com/questions/32137334/flexbox-min-height-margin-auto-and-internet-explorer
// If in doubt add a wrapper

// https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
/**

* 1. Avoid the IE 10-11 `min-height` bug.
* 2. Set `flex-shrink` to `0` to prevent some browsers from
*    letting these items shrink to smaller than their content's default
*    minimum size. See http://bit.ly/1Mn35US for details.
* 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
*/
.flexbox,
.no-flexbox.flexboxlegacy,
.no-flexbox.flexboxtweener {
	.site-body {
		display: flex;
		flex-direction: column;
		height: 100%; /* 1, 3 */
	}

	.site-section--header,
	.site-section--footer {
		flex: none; /* 2 */
	}

	.site-section--main {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto; /* 2 */
		width: 100%;

	  &:after {
		content: '\00a0'; /* &nbsp; */
		display: block;
		height: 0px;
		visibility: hidden;
	  }

	.page-container {
		flex: 1 0 auto; /* 2 */
		}
	}
}

.col-gutter {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
}

.col-gutter--outdent {
	margin-left: -@l-site--col-gutter;
	margin-right: -@l-site--col-gutter;
}
