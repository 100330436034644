.page-introduction {
	position: relative;
	z-index: @zindex--page-introduction;
}

.page-introduction__frame {};

.page-introduction__image {
	margin: 1em auto;
	width: 62.5%;
	height: auto;

	.mq--sm({
		width: 50%;
	});

	.mq--md--up({
		width: 40%;
	});
}

.page-introduction__body {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;

	&.rich-text p {
		position: relative;
		z-index: 2;
	}
}

.page-introduction__handprint {
	pointer-events: none;
	position: absolute;
	z-index: 1;
	right: 15%;
	bottom: 0;

	.mq--xl({
		right: 10%;
	});

	.mq--lg--down({
		right: 22.5%;
		bottom: 25%;
		width: 30%;
		height: auto;
	});

	.mq--md--down({
		right: 5%;
		bottom: -1em;
	});

	.mq--sm--down({
		bottom: auto;
		top: -20%;
		width: 35%;
	});
}

// These styles are backwards to display a specifc default/base first
// Beware of undoing rules at smaller breakpoints
.page-introduction--theme-default,
.page-introduction--theme-custom {
	.page-introduction__frame {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		width: (16 / @l-page-container--col--sm--up * 100%);
		font-size: 1.3125rem;

		.mq--xs({
			@cols: @l-page-hero--col--xs;
			width: (@cols / @l-page-container--col--xs * 100%);
			padding: (0.25 / @l-page-container--col--xs * 100%) (2 / @l-page-container--col--xs * 100%);
		});

		.mq--sm--down({
			font-size: 1rem;
		});

		.mq--sm({
			@cols: @l-page-hero--col--sm--up;
			width: (@cols / @l-page-container--col--sm--up * 100%);
			padding: ((1 / 3) / @l-page-container--col--sm--up * 100%) (3 / @l-page-container--col--sm--up * 100%);
		});

		.mq--md({
			@cols: 17;
			width: (@cols / @l-page-container--col--sm--up * 100%);
			font-size: 1.125rem;
		});

		.mq--md--up({
			padding: ((1 / 3) / @l-page-container--col--sm--up * 100%) (1 / @l-page-container--col--sm--up * 100%);
		});

		.mq--xl({
			@cols: @l-page-body--content-box--col--xl;
			width: (@cols / @l-page-container--col--sm--up * 100%);
			font-size: unit(4 / 3, rem);
		});
	}

	.page-header--has-hero + & {
		margin-top: 0;

		.mq--md--up({
			margin-top: -((1 / 3) / @l-page-container--col--sm--up * 100%);

			.page-introduction__frame {
				margin-top: -1em;
			}
		});
	}
}

.page-introduction--theme-default {
	.page-introduction--theme(@color-heading);
}

// We inherit from `.page-introduction--theme-default/custom` so the base styles require undoing and redoing, beware!
.page-introduction--theme-mission-statement {
	.page-introduction--theme(@palette-primary-maroon);

	.page-introduction__frame {
		font-size: unit(4 / 3, rem);
		width: (15 / @l-page-container--col--sm--up * 100%);
		padding-right: (2 / @l-page-container--col--sm--up * 100%);
	}

	.home-carousel + & {
		// Offset self

		.page-introduction__frame {
			// … and offset text
			@line-count: 2;
			margin-top: -(1em + (@line-count * @line-height--primary--base));
		}
	}

	.mq--xs({
		.page-introduction__frame {
			@cols: @l-page-hero--col--xs;
			width: (@cols / @l-page-container--col--xs * 100%);
			padding: (0.25 / @l-page-container--col--xs * 100%) (2 / @l-page-container--col--xs * 100%);
		}
	});

	.mq--sm({
		.page-introduction__frame {
			@cols: @l-page-hero--col--sm--up;
			width: (@cols / @l-page-container--col--sm--up * 100%);
		}
	});

	.mq--sm--down({
		.page-introduction__frame {
			font-size: 1rem; // Match `.page-introduction--theme-default/custom`
		}

		// Resets :(
		.home-carousel + & {
			margin-top: 0;

			.page-introduction__frame {
				margin-top: 0;
			}
		}
	});

	.mq--md({
		.page-introduction__frame {
			width: (14 / @l-page-container--col--sm--up * 100%);
			font-size: 1.25rem;
		}

		.home-carousel + & {
			.page-introduction__frame {
				@line-count: 1;
				margin-top: -(1em + (@line-count * @line-height--primary--md));
			}
		}
	});

	.mq--md--up({
		@padding-y: ((1 / 3) / @l-page-container--col--sm--up * 100%);

		.page-introduction__frame {
			text-align: left;
			margin-left: (1.5 / @l-page-container--col--sm--up * 100%);
			padding: @padding-y (1 / @l-page-container--col--sm--up * 100%);
		}

		.home-carousel + & {
			// Offset self
			margin-top: -@padding-y;
		}
	});

	.mq--xl({
		.page-introduction__frame {
			font-size: 1.375rem;
			width: (12 / @l-page-container--col--sm--up * 100%);
		}

		.home-carousel + & {
			.page-introduction__frame {
				@line-count: 2;
				margin-top: -(1em + (@line-count * @line-height--primary--xl));
			}
		}
	});
}
