.menu-hamburger--initial() {
	transition: transform 750ms @timing-function--ease-out--expo 250ms;

	> .ingredient {
		&.-bun {
			> .bar {
				transition: transform 500ms @timing-function--ease-in-out--back;
			}
		}

		&.-patty {
			> .bar {
				transition: transform 250ms cubic-bezier(0.175, 0.885, 0.320, 1.540) 250ms;
			}
		}
	}
}

.menu-hamburger--open() {
	transform: rotate(180deg);

	> .ingredient {
		&.-patty {
			> .bar {
				transform: scaleX(0);
				transition-duration: (1s / 3);
				transition-timing-function: cubic-bezier(0.620, -0.540, 0.735, 0.045);
				transition-delay: 0s;
			}
		}

		&.-bun {
			@rotation: 45deg;

			> .bar {
				transition-duration: 750ms;
			}

			&.-top {
				> .bar {
					transform: translateY(0) rotate(@rotation);
				}
			}

			&.-bottom {
				> .bar {
					transform: translateY(0) rotate(-@rotation);
				}
			}
		}
	}
}

.menu-hamburger(@width: 2rem; @height: 2rem;) {
	position: absolute;
	top: 0;
	left: 0;
	width: 2.75rem;
	height: 2.75rem;
	margin: 0;
	cursor: pointer;

	> .ingredient {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		> .bar {
			position: absolute;
			display: block;
			margin-top: -1px;
			margin-left: auto;
			margin-right: auto;
			top: 50%;
			left: 0;
			right: 0;
			width: 18px;
			height: 2px;
			background-color: #fff;
		}

		&.-bun.-top {
			> .bar {
				transform: translateY(-6px);
			}
		}

		&.-patty {
			> .bar {
			}
		}

		&.-bun.-bottom {
			> .bar {
				transform: translateY(6px);
			}
		}
	}

	.menu-hamburger--initial();
}
