.page-section {
	.clearfix();
	margin-left: auto;
	margin-right: auto;

	.page-body & {
		+ .page-section {
			margin-top: @l-page-section--margin-y--lg;

			.mq--sm--down({
				margin-top: @l-page-section--margin-y--sm;
			});
		}
	}
}

.page-section__header {
}

.page-section__text-limit {
	.l-text-x-limit();
}

.page-section__title {
	.t-fout-mask();
	.t-section-heading();
	color: @color-heading;

	.page-section__header & {
		margin-top: 1rem;
		margin-bottom: 1.5rem;
	}
}

.page-section--content {
	position: relative;

	.page-layout &:last-of-type {
		.content-item--tab-set:last-child {
			.mq--sm({
				margin-bottom: -(@l-page-section--padding-y--sm + @l-page-layout--margin-s);
			});

			.mq--md({
				margin-bottom: -(@l-page-section--padding-y--lg + @l-page-layout--margin-s);
			});

			.mq--lg--up({
				margin-bottom: -(@l-page-section--padding-y--lg + @l-page-layout--margin-s);
			});
		}
	}
}

.page-section--themed {
	padding-top: @l-page-section--padding-y--lg;
	padding-bottom: @l-page-section--padding-y--lg;

	.mq--sm--down({
		padding-top: @l-page-section--padding-y--sm;
		padding-bottom: @l-page-section--padding-y--sm;
	});

	.content-item--featured-content:last-child,
	.content-item--text-box-group:last-child {
		.mq--sm--down({
			margin-bottom: -@l-page-section--padding-y--sm;
		});

		.mq--md--up--lg({
			margin-bottom: -@l-page-section--padding-y--lg;
		});
	}

	.content-item--tab-set:last-child {
		margin-bottom: -@l-page-section--padding-y--lg;

		.mq--sm--down({
			margin-bottom: -@l-page-section--padding-y--sm;
		});
	}

	.mq--xs({
		// We want to push the background colour out to the edges, so we reset the parent `.page-body` horizontal padding
		@outdent: (@l-page-body--padding-x--col--xs / @l-page-body--content-box--col--xs * 100%);
		margin-left: -@outdent;
		margin-right: -@outdent;

		// Now we reset that padding back again to treat everything as before
		padding-left: @outdent;
		padding-right: @outdent;
	});

	.mq--xl({
		.page-article--has-no-aside & {
			@outdent: (@l-page-section--outdent--col--xl / @l-page-body--content-box--col--xl * 100%);

			margin-left: -@outdent;
			margin-right: -@outdent;

			padding-left: @outdent;
			padding-right: @outdent;
		}
	});
}

.page-section--theme-default {
	background-color: @theme-default--bg;
}
