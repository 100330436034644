.site-nav {
	font-family: @font-family-secondary;
	line-height: 1;
	color: #fff;

	> .navburger {
		.menu-hamburger();

		.mq--lg--up({
			display: none;
		});
	}

	// 👁 CONTEXT SPY
	> ._context-spy {
		.mq--md--down({
			font-family: 'small';
		});

		.mq--lg--up({
			font-family: 'large';
		});
	}
}

.site-nav__menubutton {
	.button-reset();
	.sr-only();
	display: none; // Default
	position: absolute;
	top: 0;
	left: 0;

	> .label {
		.hide-text();
	}

	&:focus {
		clip: auto;
		overflow: visible;
		outline-offset: -@l-site--col-gutter;

		.mq--sm--down({
			width: @l-site-header--height--sm;
			height: @l-site-header--height--sm;
		});

		.mq--md({
			width: @l-site-header--height--md;
			height: @l-site-header--height--md;
		});
	}
}

.site-nav__list {
	list-style: none;
	margin: 0;
	padding: 0;
	.t-fout-mask();
}

.site-nav__item {
	&.is-hidden-from-inline-main-nav {
		.mq--lg--up({
			display: none;
		});
	}
}

.site-nav__item-label {
	color: #fff;
	.t-link-underline-scaffolding();
	border-bottom-color: transparent;

	.mq--lg--up({
		.site-nav:hover .site-nav__item--parent.is-active & {
			.link-underline--theme--hover(saturate(mix(@color-brand-yellow, @palette-primary-orange, 62.5%), 20%), 100%);
		}
	});

	&[href] {
		&:hover,
		&:focus {
			// border-bottom-color: saturate(mix(#fff, @palette-primary-orange, 50%), 20%);
			// border-bottom-color: @color-brand-yellow !important;
			.link-underline--theme--hover(@color-brand-yellow, 100%) !important;
			transition-duration: 0ms;
		}
	}

	.site-nav__item--parent.is-active &,
	.site-nav__item--parent.-is-active-sub-nav & {
		transition-duration: 250ms;
		.link-underline--theme--hover(@color-brand-yellow, 100%);
	}
}

.site-nav__sub-nav {
	
}

.site-nav__sub-nav-group-list,
.site-nav__sub-nav-group-item-list {
	// Resets
	list-style: none;
	padding-left: 0;
}

.site-nav__sub-nav-group-heading {
	// .content-item--text .content-item__title
	
	@font-size--base: 0.6875rem;
	.t-fout-mask();
	display: block;
	margin: 0;
	color: @color-heading;
	font-family: @font-family-secondary;
	font-size: @font-size--base;
	line-height: 1.75;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.2em;

	.mq--sm--down({
		font-size: (0.625rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});
	
	a {
		color: currentColor;
		.t-link-underline-scaffolding(1px);
		border-bottom-color: transparent;
		
		&:hover,
		&:focus {
			border-bottom-color: currentColor;
		}
	}
}

.site-nav__sub-nav__item {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	line-height: (4 / 3);
}

.site-nav__sub-nav__item-label {
	color: @color-heading;
	.t-link-underline-scaffolding(1px);
	border-bottom-color: transparent;

	&:hover,
	&:focus {
		color: @link-color;
		.link-underline--theme--hover(@link-color, 25%);
	}
}
