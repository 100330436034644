.control-base() {
	padding: @control-padding--y @control-padding--x;
	color: @color-base--600;
	font-size: @control-font-size;
	line-height: @control-line-height;
	background-color: #fff;
	border: @control-border-width solid @color-accent--300;
	box-shadow: inset 0 -3px 0 @color-accent--100;
	transition: none 500ms @timing-function--ease-out--expo;
	transition-property: color, border-color, box-shadow;

	&:read-write:hover {
		border-color: @color-brand--primary;
		transition-duration: 250ms;
	}

	&:read-write:focus {
		outline: 0;
		color: @color-base--700;
		border-color: @color-brand--primary;
		box-shadow: inset 0 -3px 0 @color-accent--300;
		transition-duration: 250ms;
	}
}

.control-reset() {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
	background-clip: border-box;
}

.input-reset() {
	.control-reset();
	font-family: inherit;

	&::placeholder {
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	.@{fout-class--loading} & {
		color: transparent;

		&::placeholder {
			color: transparent;
		}
	}
}

.select-reset() {
	.control-reset();
	.fout-mask();
	font-family: inherit;
	appearance: none;
}

.button-reset() {
	.control-reset();
	appearance: none;
	display: inline-block;
	text-decoration: none;

	&:focus,
	&:hover {
		text-decoration: inherit;
	}
}
