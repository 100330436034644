.media-carousel {
	&[data-aspect-ratio="3:2"] {
		.media-carousel__items {
			padding-top: (@l-carousel--aspect-ratio * 100%);
		}
	}

	&.is-single-item {
		.flickity-viewport {
			background-color: @theme-default--bg;
		}

		.media-carousel__item {
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
		}
	}

	&.is-multiple-item {
		// Mask reflows
		.media-carousel__items {
			.media-carousel__item {
				visibility: hidden;
			}

			&.flickity-enabled {
				.media-carousel__item {
					visibility: visible;
				}
			}
		}
	}
}

.media-carousel__items {
	position: relative;
	z-index: @zindex--media-carousel__items;

	> .flickity-viewport {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.media-carousel__item {
	position: absolute;
	top: 0;
}

.media-carousel__item__figure {
	position: relative;
	margin: 0;
	padding: 0;
	height: 100%;
	.prevent-user-select();

	.media-caption {
		display: none;
	}

	.media-figure__body {
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.media-carousel__footer {
	position: relative;

	.media-carousel--has-no-captions& {
		margin-left: auto;
		margin-right: auto;
	}
}

.media-carousel__pagination {
	@font-size--base: 0.75rem;
	.t-fout-mask();
	margin-top: 0.75em; // Match .media-caption
	padding-left: @l-site--col-gutter;
	padding-right: 2.5em;
	font-size: @font-size--base;
	line-height: (5 / 3);
	font-family: @font-family-secondary;

	.mq--xs({
		padding-right: 1.5em;
		font-size: (0.625rem / @font-size--factor--sm);
	});

	.mq--sm({
		padding-right: 2em;
		font-size: (0.6875rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	> span {
		display: inline-block;
	}

	.media-carousel__pagination-item--current {
		text-align: right;
		white-space: nowrap;
	}

	.media-pagination--theme();

	.media-carousel--has-captions & {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.media-carousel__caption-list {
	.media-caption {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		&.is-hidden {
			visibility: hidden;
		}
	}
}
