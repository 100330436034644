.content-item--featured-content {
	// .l-content-item--last-item-in-themed-section();
}

.featured-content-group {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.featured-content-item {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-basis: 100%;
	flex-grow: 1;
}

.featured-content-item__media {
	margin: 0;
	padding: 0;
	position: relative;
	overflow: hidden;
	flex: 1 0 auto;

	&:after {
		content: "";
		display: block;
	}

	.mq--xs({
		width: 25%;

		&:after {
			padding-top: 100%;
		}
	});

	> img {
		position: absolute;
		top: 0;
		height: 100%;
		width: auto;
		max-width: none;

		// Horizontal centering
		left: 50%;
		transform: translate(-50%);
	}
}

.featured-content-item__body {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.featured-content-item__body-prop {
	display: none;
}

.featured-content-item__copy {
	.t-fout-mask();

	// FLEXBOX

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	// IE fix
	width: 100%;
}


@title-font-size--base: 1rem;
@title-font-size--xs: 1.125rem;
@title-font-size--sm: unit(4 / 3, rem);
@title-font-size--lg: 1.5rem;
@title-line-height--base: 1.25;

.featured-content-item__title {
	margin: 0.5em 0 0 0;
	font-family: @font-family-secondary;
	font-size: @title-font-size--base;
	line-height: @title-line-height--base;
	font-weight: normal;
	color: @color-heading;

	a {
		color: currentColor;
		.t-link-underline-scaffolding();
	}

	.mq--xs({
		font-size: @title-font-size--xs !important; // HACK
	});

	.mq--sm--up--md({
		font-size: @title-font-size--sm;
	});
}

@description-font-size--base: 0.9375rem;
.featured-content-item__description {
	margin-top: 0.25em;
	margin-bottom: -0.25em;
	font-size: @description-font-size--base;

	// HACK overriding
	line-height: inherit !important;

	.mq--xs({
		font-size: 0.75rem !important; // HACK
	});

	.mq--xl({
		font-size: (@description-font-size--base / @font-size--factor--xl);
	});
}

.featured-content-item__title,
.featured-content-item__description {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
}

.featured-content-item--theme-default {
	background-color: @theme-default--bg;
}

.featured-content-item--theme-custom {
	.featured-content-item__title,
	.featured-content-item__description {
		color: currentColor;
	}
}


// ============================================================================
// Mixin
.indent-sizing(@col-count, @content-col-total) {
	.featured-content-item {
		padding-left: (@col-count / @content-col-total * 100%);
		padding-right: (@col-count / @content-col-total * 100%);
	}

	.featured-content-item__body {
		@col-offset-count: (@content-col-total - (@col-count * 2));
		padding-right: (0.5 / @col-offset-count * 100%);
	}
}

// Single Item
.content-item--featured-content {
	&.is-single-item {

		.mq--sm({
			.indent-sizing(@l-text--margin-x--col--sm, @l-page-body--content-box--col--sm);
		});

		.mq--md({
			.indent-sizing(@l-text--margin-x--col--md, @l-page-body--content-box--col--md);
		});

		.mq--lg({
			.indent-sizing(@l-text--margin-x--col--lg, @l-page-body--content-box--col--lg);
		});

		.mq--xl({
			.indent-sizing(@l-text--margin-x--col--xl, @l-page-body--content-box--col--xl);
		});

		.featured-content-item__media {
			.mq--sm--up({
				order: 1;
				width: 50%;

				&:after {
					padding-top: 100%;
				}
			});
		}

		.featured-content-item__title {
			.mq--lg({
				font-size: @title-font-size--lg;
			});

			.mq--xl({
				font-size: (@title-font-size--lg / @font-size--factor--xl);
			});
		}
	}
}

// ============================================================================
// Mixin
.item-count-sizing(@count) {
	.featured-content-item:nth-last-child(@{count}n):first-child,
	.featured-content-item:nth-last-child(@{count}n):first-child ~ .featured-content-item {
		@width: percentage(1 / @count);
		width: @width;

		.featured-content-item__copy {
			padding-right: 5%;
		}
	}
}

.content-item--featured-content {
	&.is-multiple-item {
		.featured-content-item {
			.mq--sm--up({
				width: 50%;
				flex: 0 1 auto; // Reset

				.featured-content-item__body {
					flex-basis: 60%;
					flex: 0 1 auto;
				}

				.featured-content-item__media {
					&:after {
						padding-top: 150%;
					}
				}
			});
			
			.mq--sm--up--md({
				.featured-content-item__media {flex-basis: 40%}

				.featured-content-item__body {flex-shrink: 1;}
			});

			.featured-content-item__title {
				.mq--md--up--lg({
					font-size: 1.125rem;
				});

				.mq--xl({
					font-size: (1.125rem / @font-size--factor--xl);
				});
			}

			.featured-content-item__description {
				.mq--md({
					font-size: 0.875rem;
				});
			}

			&:nth-child(odd) {
				.featured-content-item__body {
					.mq--sm({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--sm / 2));
						padding-left: 0;
					});

					.mq--md({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--md / 2));
						padding-left: 0;
					});

					.mq--lg({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--lg / 2));
						padding-left: percentage(0.25 / (@l-page-body--content-box--col--lg / 2));
					});

					.mq--xl({
						padding-right: percentage(0.5 / (@l-page-body--content-box--col--xl / 2));
						padding-left: percentage(0.25 / (@l-page-body--content-box--col--xl / 2));
					});
				}
			}

			&:nth-child(even) {
				.featured-content-item__media {
					.mq--sm--up({
						order: 1;
					});
				}

				.featured-content-item__body {
					.mq--sm({
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--sm / 2));
						padding-right: 0;
					});

					.mq--md({
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--md / 2));
						padding-right: 0;
					});

					.mq--lg({
						padding-right: percentage(0.25 / (@l-page-body--content-box--col--lg / 2));
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--lg / 2));
					});

					.mq--xl({
						padding-right: percentage(0.25 / (@l-page-body--content-box--col--xl / 2));
						padding-left: percentage(0.5 / (@l-page-body--content-box--col--xl / 2));
					});
				}
			}
		}

		.featured-content-item:nth-last-child(3n):first-child,
		.featured-content-item:nth-last-child(3n):first-child ~ .featured-content-item {
			.featured-content-item__title {
				.mq--md--up({
					font-size: 1.125rem;
				});
			}

			.featured-content-item__description {
				.mq--lg--up({
					font-size: 0.875rem;
				});
			}
		}

		.featured-content-item:nth-last-child(4n):first-child,
		.featured-content-item:nth-last-child(4n):first-child ~ .featured-content-item {
			.featured-content-item__title {
				.mq--xl({
					font-size: 1.125rem;
				});
			}

			.featured-content-item__description {
				.mq--lg--up({
					font-size: 0.75rem;
				});
			}
		}

		.featured-content-item:nth-last-child(5n):first-child,
		.featured-content-item:nth-last-child(5n):first-child ~ .featured-content-item {
			.featured-content-item__description {
				.mq--lg--up({
					font-size: 0.875rem
				});

				.mq--xl({
					font-size: (0.875rem / @font-size--factor--xl);
				});
			}
		}

		.mq--lg--up({
			// Styling for when there’re total counts in multiples of 3, 4, or 5
			.featured-content-item:nth-last-child(3n):first-child,
			.featured-content-item:nth-last-child(3n):first-child ~ .featured-content-item,
			.featured-content-item:nth-last-child(4n):first-child,
			.featured-content-item:nth-last-child(4n):first-child ~ .featured-content-item,
			.featured-content-item:nth-last-child(5n):first-child,
			.featured-content-item:nth-last-child(5n):first-child ~ .featured-content-item {
				flex-direction: column;

				.featured-content-item__media {
					// Reset
					order: 0;
					flex-basis: auto;
					flex-grow: 0;

					> img {
						// Reset
						left: 0;
						transform: none;
					}

					&:after {
						padding-top: 100%;
					}
				}

				.featured-content-item__body {
					// Reset all the things
					padding-right: 0;
					padding-left: 0;
					flex-grow: 1;
					flex-basis: 75%;
					flex: 0 0 auto;
				}

				.featured-content-item__copy {
					width: 100%;
					flex-basis: 100%;
					margin-right: -25%;
				}

				.featured-content-item__body-prop {
					display: block;
					flex: 1 0 auto;
					flex-basis: 25%;

					&:after {
						content: "";
						display: block;
						padding-top: 400%;
					}
				}
			}

			.item-count-sizing(3);
			.item-count-sizing(4);
			.item-count-sizing(5);
		});
	}
}
