@timing-function--ease-out--expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);

// Typography
// --------------------------------------------------
.link-underline--theme(@color, @underline-color, @underline-opacity: 50%) {
	color: @color;
	.link-underline--theme--initial(@underline-color, @underline-opacity);

	&:hover,
	&:focus {
		color: @color;
		.link-underline--theme--hover(@underline-color);
	}
}

.link-underline--theme--initial(@underline-color; @underline-opacity: 25%) {
	border-bottom-color: fade(@underline-color, @underline-opacity);
}

.link-underline--theme--hover(@underline-color; @underline-opacity: 75%) {
	border-bottom-color: fade(@underline-color, @underline-opacity);
}

.rich-text--theme(@base, @headings, @links, @accent, @blockquote: false) {
	a {
		.link-underline--theme(@links, @accent, percentage(1 / 3));
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	code,
	dt {
		color: @headings;
	}

	hr {
		border: 0;
		border-top: 1px solid fadeout(@base, 85%);
	}

	blockquote {
		color: @blockquote;
		border-left-color: fadeout(@base, 50%);

	a {
		color: inherit;
		border-bottom-color: inherit;
		}
	}

	table {
		td,
		th {
			border-bottom-color: fadeout(@base, 85%);
		}

		thead,
		tfoot {
			td,
			th {
				color: @headings;
			}
		}

		tfoot {
			td,
			th {
				border-top-color: fadeout(@base, 85%);
			}
		}
	}
}

// Call to Action
// --------------------------------------------------
.call-to-action--theme(@color) {
	color: @color;
	border-color: fadeout(@color, 50%);

	&:hover {
		border-color: fadeout(@color, 25%);
	}
}

.call-to-action--theme-custom(@name, @color) {
	.call-to-action--theme-@{name} {
		.call-to-action--theme(@color);
		transition: border-color 500ms @timing-function--ease-out--expo;

		&:hover {
			transition-duration: 250ms;
		}
	}
}

// Media Caption
// --------------------------------------------------
.media-caption--theme(@color: @color-caption) {
	color: @color;

	a {
		.link-underline--theme(@color, @color, 20%);
	}
}

// Media Pagination
// --------------------------------------------------
.media-pagination--theme(@color: @color-caption) {
	color: @color;
}

// Media Figure - Statistic
// --------------------------------------------------
.media-figure--statistic-theme(@bg, @title, @definition) {
	.media-figure__body {
		background-color: mix(multiply(@bg, @bg), @bg, (1 / 3 * 100%));
	}

	.media-figure--statistic__title {
		color: @title;
	}

	.media-figure--statistic__definition {
		color: @definition;
	}
}

// Tabs Set Item
// --------------------------------------------------
.tab-set-item--theme-custom(@name, @bg) {
	.tab-set-item--theme-@{name} {
		.tab-set-item--theme(@bg);
	}
}

.tab-set-item--theme(@bg) {
	@body-light: lighten(mix(@bg, #fff, 15%), 5%, relative);
	@body-dark: darken(mix(@bg, #000, 40%), 15%, relative);
	@body: contrast(@bg, @body-dark, @body-light);

	// If dark, darken
	// 43% is the default contrast threshold
	// http://lesscss.org/functions/#color-operations-contrast
	.child-colors(@color) when (luma(@color) < 43) {
		@heading: darken(@color, 15%, relative);
		@caption: mix(@bg, @color, (1 / 3 * 100%));
	}

	// Else lighten!
	.child-colors(@color) when (default()) {
		@heading: lighten(@color, 5%, relative);
		@caption: mix(@bg, @color, 25%);
	}

	background-color: @bg;
	color: @body;
	.child-colors(@body);

	.tab-set__item__title {
		color: @heading;
	}

	.tab-set__item__body {
		a {
			.link-underline--theme(@body, @body, 20%);
		}
	}

	.call-to-action {
		.call-to-action--theme(@heading);
	}

	.media-caption {
		.media-caption--theme(@caption);
	}

	.media-carousel__pagination {
		.media-pagination--theme(@caption);
	}

	.media-figure--statistic {
		.media-figure--statistic-theme(@bg, @body, @caption);
	}
}

// Featured Content Item
// --------------------------------------------------
.featured-content-item--theme-custom(@name, @tint) {
	.featured-content-item--theme-@{name} {
		.featured-content-item--theme(@tint);
	}
}

.featured-content-item--theme(@tint) {
	@color: contrast(@tint, #fff, darken(mix(@tint, #000, (1 / 3 * 100%)), 15%, relative), 50%);
	background-color: @tint;
	color: @color;

	.featured-content-item__title a {
		border-bottom-color: fadeout(@color, 100%);
	}

	&:hover {
		.featured-content-item__title a {
			border-bottom-color: fadeout(@color, 25%);
		}
	}
}

// Block Content Item
// --------------------------------------------------
.content-text-box--theme-custom(@name, @tint) {
	.content-text-box--theme-@{name} {
		.content-text-box--theme(@tint);
	}
}

.content-text-box--theme(@tint) {
	@color: contrast(@tint, #fff, darken(mix(@tint, #000, (1 / 3 * 100%)), 15%, relative), 50%);
	background-color: @tint;
	color: @color;

	.content-text-box__description {
		a {
			.link-underline--theme(@color, @color, 25%);
		}
	}

	&.is-linkable {
		.content-text-box__title a {
			border-bottom-color: fadeout(@color, 100%);
		}

		&:hover {
			.content-text-box__title a {
				border-bottom-color: fadeout(@color, 25%);
			}
		}
	}
}

// Page Introduction
// --------------------------------------------------
.page-introduction--theme-custom(@name, @tint) {
	.page-introduction--theme-@{name} {
		.page-introduction--theme(@tint);
	}
}

.page-introduction--theme(@tint) {
	.page-introduction__frame {
		background-color: @tint;
		@color: contrast(@tint, #fff, darken(mix(@tint, #000, (1 / 3 * 100%)), 15%, relative));
		color: @color;

		a {
			.link-underline--theme(@color, @color, 25%);
		}
	}
}

// Page Navigation
// --------------------------------------------------
.page-index__item__label--theme-custom(@name, @tint) {
	.page-index__item__label--theme-@{name} {
		.page-index__item__label--theme(@tint);
	}

	.page-index__item__label--theme(@tint) {
		.link-underline--theme(@tint, @tint, 0%);
	}
}

// Page Section
// --------------------------------------------------
.page-section--theme-custom(@name, @tint) {
	.page-section--theme-@{name} {
		.page-section--theme(@tint);
	}
}

.page-section--theme(@tint) {
	@caption: mix(#fff, @tint, 12.5%);
	@bg: desaturate(fade(@tint, 7.5%), 50%);
	color: @tint;
	background-color: @bg;

	.rich-text {
		.rich-text--theme(@base: @tint, @headings: @tint, @links: @tint, @accent: @tint, @blockquote: @tint);
	}

	.page-section__title,
	.content-item__title {
		color: inherit;
	}

	.media-carousel.is-single-item .flickity-viewport {
		background-color: darken(@bg, 5%);
	}

	.media-carousel__item__figure {
		background-color: @bg;
	}
	
	.content-item__media-grid .media-figure .media-figure__body {
		// background-color: fade(darken(@bg, 12.5%), 12.5%);
	}

	.media-caption {
		.media-caption--theme(@caption);
	}

	.media-carousel__pagination {
		.media-pagination--theme(@caption);
	}

	.call-to-action {
		.call-to-action--theme(@tint);
	}
}
