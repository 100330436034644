.site-section--footer {
	font-family: @font-family-secondary;
	color: #fff;

	a {
		.t-link-underline-scaffolding();
		.link-underline--theme(#fff, #fff, 0%);
	}
}

.site-footer__container {
	margin-left: auto;
	margin-right: auto;

	// Use identical sizing to mimic the orange border of `.page-container`
	.mq--xs({
		width: @l-page-container--pct--xs;
	});

	.mq--sm--up({
		width: @l-page-container--pct--sm--up;
	});
}

.site-footer__section {
	.clearfix();
	margin-left: auto;
	margin-right: auto;
	margin-top: 3rem;
	margin-bottom: 4rem; // Greater bottom margin like a picture frame
	width: @l-footer-section--pct--lg;

	& + & {
		margin-top: 4.5rem;
	}

	.mq--xs({
		width: @l-footer-section--pct--xs;
	});

	.mq--sm({
		width: @l-footer-section--pct--sm;
	});

	.mq--md({
		width: @l-footer-section--pct--md;
	});

	.mq--xl({
		width: @l-footer-section--pct--xl;
	});
}


// ============================================================================
// Mixins used locally
//
//
.site-footer-list(@outdent, @content-width, @page-margin, @column-count) {
	margin-left: -(@outdent / @content-width * 100%);
	margin-right: -(@outdent / @content-width * 100%);

	.site-footer-list__item {
		padding-left: (@outdent / (@content-width + @page-margin) * 100%);
		padding-right: (@outdent / (@content-width + @page-margin) * 100%);
		width: (100% / @column-count);

		&:nth-child(n) {
			clear: none; // Reset base clearing rule as defined below
		}

		&:nth-child(@{column-count}n+1) {
			clear: left;
		}
	}
}

.site-footer-list__item--special(@count) {
	.site-footer-list__item:nth-last-child(@{count}n):first-child,
	.site-footer-list__item:nth-last-child(@{count}n):first-child ~ .site-footer-list__item {
		clear: none;
		width: (100% / @count);

		&:nth-child(@{count}n+1) {
			clear: left;
		}
		
		&.site-footer-list__item--social {
			width: ((100% / @count) * 2);
			
			+ .site-footer-list__item--spacer {
				display: none;
			}
		}
	}
}

// Back to style rules again
.site-footer-list {
	.clearfix();
	margin-top: -0.75em;
	margin-bottom: -0.75em;
	padding: 0;
	list-style: none;

	.site-footer-list__item {
		padding-top: 1rem;
		padding-bottom: 1rem;
		float: left;

		&.site-footer-list__item--spacer {
			display: none;
		}
	}

	.site-footer-list(0.25, @l-footer-section--col--lg, @l-page-container--margin-x--col--sm--up, 4);
	.site-footer-list__item--social { width: 50%; }

	.mq--xs({
		.site-footer-list(1, @l-footer-section--col--xs, @l-page-body--padding-x--col--xs, 2);
		.site-footer-list__item--social { width: 100%; }
	});

	.mq--sm({
		.site-footer-list(1, @l-footer-section--col--sm, @l-page-container--margin-x--col--sm--up, 2);
		.site-footer-list__item--social { width: 100%; }
 	});

	.mq--md({
		.site-footer-list(0.5, @l-footer-section--col--md, @l-page-container--margin-x--col--sm--up, 3);
		.site-footer-list__item--social { width: 100%; }
	});

	.mq--xl({
		.site-footer-list__item--special(@count: 5);
		.site-footer-list__item--special(@count: 6);
	});
}

.site-footer-list__item {
	.t-fout-mask();
}

.site-footer-list__item__inner {
	margin-left: @l-site--col-gutter;
	margin-right: @l-site--col-gutter;
}

.site-footer__section--contact {
	@font-size--base: 0.75rem;
	font-size: @font-size--base;
	line-height: 1.5;
	opacity: 0.875;
	transition: opacity 500ms @timing-function--ease-out--expo;
	-webkit-font-smoothing: antialiased;

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	&:hover {
		opacity: 1;
		transition-duration: 250ms;
	}
	
	.social-service-icon-list {
		.mq--md--down({
			margin-top: 0;
		});
	}
}

.site-footer__section--colophony {
	@font-size--base: 0.75rem;
	.t-fout-mask();
	font-size: @font-size--base;
	line-height: 1.5;
	opacity: 0.75;
	transition: opacity 500ms @timing-function--ease-out--expo;
	-webkit-font-smoothing: antialiased;

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	&:hover {
		opacity: 1;
		transition-duration: 250ms;
	}
}

.site-footer__section--colophony__inner {
	margin-left: @l-site--col-gutter;
	margin-right: @l-site--col-gutter;
}
