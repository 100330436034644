.site-nav {
	.mq--lg({
		font-size: @l-site-header-font-size--lg;
		margin-left: @l-site-nav__item--gutter--lg;
		margin-right: @l-site-nav__item--gutter--lg;
	});

	.mq--xl({
		font-size: @l-site-header-font-size--xl;
		margin-left: @l-site-nav__item--gutter--xl;
		margin-right: @l-site-nav__item--gutter--xl;
	});
}

.site-nav__list {
	@media screen and @vp-lg {
		padding-top: 0.25rem;
	}
	
	@media screen and @vp-lg-up {
		position: static;
	}
}

.site-nav__item {
	position: relative;

	.mq--lg--up({
		position: static;
		display: inline-block;
		height: 100%;
		margin: 0;

		&[touch-action] {
			touch-action: none;
		}

		&.-is-active-sub-nav {
			// On top of `.site-nav__item-label` and `.site-nav__sub-nav`
			z-index: 2;
			color: @link-color;
			
			.site-nav__item-label {
				background-color: fade(#fff, 97.5%);
				
				.backdropfilter & {
					backdrop-filter: blur(10px);
					background-color: fade(#fff, 95%);
				}
			}
		}
	});
}

.site-nav__item-label {
	.mq--lg({
		padding-left: @l-site-nav__item--gutter--lg;
		padding-right: @l-site-nav__item--gutter--lg;
		line-height: @l-site-nav__item--parent-height--lg;
		height: @l-site-nav__item--parent-height--lg;
	});

	.mq--xl({
		padding-left: @l-site-nav__item--gutter--xl;
		padding-right: @l-site-nav__item--gutter--xl;
		line-height: @l-site-nav__item--parent-height--xl;
		height: @l-site-nav__item--parent-height--xl;
	});

	.mq--lg--up({
		z-index: 1; // On top of dropdown
		position: relative;

		display: inline-block;
		border-bottom: 0 !important; // HACK!
		border-top-left-radius: @border-radius;
		border-top-right-radius: @border-radius;
		transition-duration: 0ms;

		&[touch-action] {
			touch-action: none;
		}
		
		.site-nav__item--parent.is-active &,
		.site-nav__item--parent.-is-active-sub-nav & {
			border-bottom: 0 !important; // HACK!
		}
	});
}

.site-nav__item--button {
	.mq--lg--up({
		.site-nav__item-label {
			display: inline-block;
			padding: (@l-site--col-gutter * 1.5) (@l-site--col-gutter * 2) (@l-site--col-gutter * (4 / 3));
			border: 1px solid rgba(255, 255, 255, (1 / 3));
			border-radius: @border-radius;
			color: #fff;
			transition: border-color 500ms @timing-function--ease-out--expo;

			&:hover {
				border-color: rgba(255, 255, 255, 0.75);
				transition-duration: 250ms;
			}
		}
	});
}

.site-nav__sub-nav {
	.mq--lg--up({
		display: none;
		position: absolute;
		left: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);
		right: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);
		background-color: fade(#fff, 97.5%);
		border-bottom-left-radius: @border-radius;
		border-bottom-right-radius: @border-radius;
		box-shadow: 0 0 1px fade(@color-heading, 25%), 0 0 8px fade(@color-heading, 10%);
		
		.backdropfilter & {
			backdrop-filter: blur(10px);
			background-color: fade(#fff, 95%);
		}
	});
}

.site-nav__sub-nav__inner {
	@media screen and @vp-lg-up {
		margin-left: auto;
		margin-right: auto;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		width: @l-site-nav__sub-nav__inner--pct--lg;
	}
	
	@media screen and @vp-xl {
		width: @l-site-nav__sub-nav__inner--pct--xl;
	}
}

.site-nav__sub-nav-group {
	@media screen and @vp-lg-up {
		text-align: center;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.site-nav__sub-nav-group-sizing(@group-gutter-col-width, @groups-per-row, @inner-col-width) {
	margin-left: percentage(-@group-gutter-col-width / @inner-col-width);
	margin-right: percentage(-@group-gutter-col-width / @inner-col-width);
	
	.site-nav__sub-nav-group {
		@inner-col-width-with-outdent: (@inner-col-width + (@group-gutter-col-width * 2));
		margin-left: percentage(@group-gutter-col-width / @inner-col-width-with-outdent);
		margin-right: percentage(@group-gutter-col-width / @inner-col-width-with-outdent);
		
		@total-col-remainder-for-groups: @inner-col-width-with-outdent - ((@group-gutter-col-width * 2) * @groups-per-row);
		@group-col-width: (@total-col-remainder-for-groups / @groups-per-row);
		flex-basis: percentage(@group-col-width / @inner-col-width-with-outdent);
		max-width: percentage(@group-col-width / @inner-col-width-with-outdent);
	}
}

.site-nav__sub-nav-group-list {
	@media screen and @vp-lg-up {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
	
	&.-col-default {
		@media screen and @vp-lg {
			.site-nav__sub-nav-group-sizing(0.375, 6, @l-site-nav__sub-nav__inner--col--lg);
		}
		
		@media screen and @vp-xl {
			.site-nav__sub-nav-group-sizing(0.375, 6, @l-site-nav__sub-nav__inner--col--xl);
		}
	}
	
	&.-col-5 {
		@media screen and @vp-lg {
			.site-nav__sub-nav-group-sizing(0.375, 5, @l-site-nav__sub-nav__inner--col--lg);
		}
		
		@media screen and @vp-xl {
			.site-nav__sub-nav-group-sizing(0.375, 5, @l-site-nav__sub-nav__inner--col--xl);
		}
	}
}

.site-nav__sub-nav-group-heading + .site-nav__sub-nav-group-item-list {
	@media screen and @vp-lg-up {
		margin-top: 1.25rem;
	}
}

.site-nav__sub-nav__item {
	@media screen and @vp-lg-up {
		margin-top: 0.875rem;
		margin-bottom: 0.875rem;
		line-height: 1.5;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	@media screen and @vp-lg {
		font-size: 0.8125rem; // 13px
	}
	
	@media screen and @vp-xl {
		font-size: (0.875rem / @font-size--factor--xl);
	}
}
