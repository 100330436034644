.page-section--header {}

.page-header__title-group {
	text-align: center;
}

.page-header--has-no-hero {
	.page-header__title-group {
		@padding-top: 1.5;
		padding-top: (@padding-top / @l-page-container--col--xs * 100%);

		.mq--sm--up({
			padding-top: (@padding-top / @l-page-container--col--sm--up * 100%);
		});
	}

	+ .page-section--introduction {
			margin-top: 2rem
	}
}

.page-header--has-hero {
	position: relative;

	.page-header__title-group {
		position: absolute;
		z-index: @zindex--page-header__title-group;
		bottom: 17.5%;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: (@l-page-hero--col--xs / @l-page-container--col--xs * 100%);
		padding-left: (1.5 / @l-page-hero--col--xs * 100%);
		padding-right: (1.5 / @l-page-hero--col--xs * 100%);

		.mq--sm({
			bottom: 5%;
		});

		.mq--md({
			bottom: 12.5%;
		});

		.mq--sm--up({
			width: (@l-page-hero--col--sm--up / @l-page-container--col--sm--up * 100%);
		});

		.page-title {
			color: #fff;
			text-shadow: 0 0 1.25em @palette-primary-off-black;

			.mq--xs({
				margin-top: 1em;
				margin-bottom: 1em;
			});
		}

		.page-byline {
			color: #fff;
			text-shadow: 0 0 1em fade(@palette-primary-off-black, (1 / 3 * 100%)), 0 0 0.125em fade(@palette-primary-off-black, 40%);

			.mq--xs({
				display: none;
			});
		}
	}
}
