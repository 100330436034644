.rich-text(@isTinyMce: false, @heading-color: @color-heading, @color-link: @palette-primary-off-black, @accent-color: @palette-primary-orange) {
	.isTinyMce(@isTinyMce) when (@isTinyMce = true) {
		body {
			> *:first-child {
				margin-top: 0;
			}

			> *:last-child {
				margin-bottom: 0;
			}

			> ol,
			> ul {
				padding-left: 2em;
			}
		}
	};

	.isTinyMce(@isTinyMce) when (@isTinyMce = false) {
		.t-fout-mask();

		margin-top: 1rem;
		margin-bottom: 1rem;
	};

	//
	.isTinyMce(@isTinyMce);

	a {
		color: inherit;
		text-decoration: none;

		.t-link-underline-scaffolding(2px);
	}

	u {
		text-decoration: none !important;
	}

	p {
		margin-top: 1em;
		margin-bottom: 1em;

		> img {
			margin-top: 1em;
			margin-bottom: 1em;
		}

		.t-style-spotlight();
	}

	img {
		max-width: 100%;
		height: auto;
	}

	pre,
	img,
	iframe,
	dl,
	blockquote,
	hr,
	table {
		margin-top: 3em;
		margin-bottom: 3em;
	}

	ul,
	ol {
		margin-top: 1em;
		margin-bottom: 1.5em;
		padding: 0;

		ul,
		ol {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			padding-left: 2em;
		}

		li {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}
	}

	ul {
		list-style: square;
	}

	ol {
		list-style: decimal;
	}

	* + ul,
	* + ol {
		marign-top: 2em;
	}

	dl {
		margin-top: 0.5em;
		margin-bottom: 0.75em;
		font-family: @font-family-secondary;
		font-size: 87.5%;
		line-height: 1.5;
		font-weight: bold;
		font-style: normal;
	}

	dd {
		margin-left: 1.5em;

		+ dt {
			margin-top: 1.5em;
		}
	}

	code {
		margin-left: 0.25em;
		margin-right: 0.25em;
	}

	pre {
		padding: 0.75em 1em;
		overflow: auto;
		word-break: normal;

		code {
			margin-left: 0;
			margin-right: 0;
			overflow-wrap: normal;
			white-space: pre-wrap;
		}
	}

	.rich-text--theme(@base: @color-body, @headings: @heading-color, @links: @color-link, @accent: @accent-color, @blockquote: @palette-secondary-blue);
}

.rich-text--extended(@isTinyMce: false) {
	.isTinyMce(@isTinyMce) when (@isTinyMce = true) {};

	.isTinyMce(@isTinyMce) when (@isTinyMce = false) {
		div.table-wrapper {
			margin: 2em -@l-site--col-gutter;

			table {
				margin: 0;

			caption {
					margin-left: @l-site--col-gutter;
					margin-right: @l-site--col-gutter;
				}
			}
		}
	};

	.isTinyMce(@isTinyMce);

	h1,
	h2,
	h3 {
		margin-top: 2em;
		margin-bottom: 1em;
		font-family: @font-family-base;
		font-size: 112.5%;
		line-height: 1.5;
		font-weight: normal;
		font-style: normal;
	}

	h4 {
		margin-top: 3em;
		margin-bottom: 1.5em;
		font-family: @font-family-secondary;
		font-size: 100%;
		line-height: 1.5;
		font-weight: bold;
		font-style: normal;
	}

	h5 {
		margin-top: 4em;
		margin-bottom: 2em;
		font-family: @font-family-base;
		text-transform: uppercase;
		font-size: 75%;
		line-height: 1.5;
		letter-spacing: 0.25em;
		font-weight: normal;
		font-style: normal;
	}

	h6 {
		margin-top: 5em;
		margin-bottom: 2.5em;
		font-family: @font-family-secondary;
		text-transform: uppercase;
		font-size: (2 / 3 * 100%);
		line-height: 1.5;
		letter-spacing: 0.25em;
		font-weight: bold;
		font-style: normal;
	}

	img {
		&[align="left"] {
			clear: both;
			float: left;
			margin-right: 2em;
		}

		&[align="right"] {
			clear: both;
			float: right;
			margin-left: 2em;
		}

		&[align="center"],
		&[align="middle"] {
			clear: both;
			margin-left: auto;
			margin-right: auto;
		}
	}

	blockquote {
		margin-right: 0;
		margin-left: 0.75em;
		padding-left: 0.75em;
		border-left: 2px solid;
		font-family: @font-family-base;
		font-style: italic;
		font-size: 112.5%;
	}

	table {
		width: 100%;
		text-align: left;
		line-height: (4 / 3);
		// stop rgba borders from doubling up (darkening)
		border-collapse: separate;
		font-family: @font-family-secondary;

		caption {
			text-align: left;
		}

		td, th {
			vertical-align: top;
			padding: 0.75em @l-site--col-gutter;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			font-weight: normal;
		}

		thead,
		tfoot {
			td,
			th {
				font-size: 87.5%;
				font-weight: normal;
			}
		}

		thead {
			td,
			th {
				border-bottom-width: 2px;
				font-weight: bold;
			}
		}

		tfoot {
			td,
			th {
				border-top-width: 2px;
				border-top-style: solid;
				border-bottom: 0;
			}
		}
	}
}
