.rich-text {
	.rich-text();
}

.rich-text--extended {
	.rich-text--extended();
}

.rich-text--primary-family {
	.t-primary-line-height();

	&.rich-text--smaller {
		font-size: 87.5%;

		.mq--xl({
			font-size: (87.5% / @font-size--factor--xl);
		});
	}

	b,
	strong,
	[style*="font-weight:"] {
		font-weight: inherit !important;
	}
}

.rich-text--secondary-family {
	font-family: @font-family-secondary;
	line-height: 1.875;

	.mq--md--down({
		line-height: 1.75;
	});

	.mq--xl({
		font-size: (1 / @font-size--factor--xl * 100%);
	});

	&.rich-text--smaller {
		font-size: 87.5%;
		line-height: 1.75;

		.mq--xl({
			font-size: (87.5% / @font-size--factor--xl);
		});
	}
}
