.site-section--header {
	.prevent-user-select();

	.mq--sm--down({
		padding-top: @l-site-header--padding-y--sm;
		padding-bottom: @l-site-header--padding-y--sm;
	});

	.mq--md({
		padding-top: @l-site-header--padding-y--md;
		padding-bottom: @l-site-header--padding-y--md;
	});

	.mq--lg({
		padding-top: @l-site-header--padding-y--lg;
	});

	.mq--xl({
		padding-top: @l-site-header--padding-y--xl;
		padding-bottom: @l-site-header--padding-y--xl;
	});

	// 👁 CONTEXT SPY
	> ._context-spy {
		.mq--md--down({
			font-family: 'small';
		});

		.mq--xl({
			font-family: 'large';
		});
	}

	&.is-pinned {
		.site-header__inner {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;

			// .site-header__logo
			// .site-header__logo-inline
			> * {
				opacity: (2 / 3);
				transition: opacity 2000ms @timing-function--ease-out--expo 1000ms;
			}

			&:hover {
				// .site-header__logo
				// .site-header__logo-inline
				> * {
					opacity: 1;
					transition-duration: 250ms;
					transition-delay: 0ms;
				}
			}
		}
	}
}

.site-header__inner {
	z-index: @zindex--site-header;
	position: relative;
	background-color: @palette-primary-orange;
	
	.mq--md({
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		.site-header__logo {
			flex-grow: 1;
		}
		
		.site-header__donations {
			// Quick and dirty override
			position: relative;
			right: auto;
			top: auto;
		}
		
		.site-header__socials {
			display: block;
			margin-right: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);
		}
	});

	.mq--md--down({
		position: static; // We need this to get the menu 100% height and positioning behaviour
		height: @l-site-nav__item--parent-height--md;
	});

	.mq--lg--up({
		display: flex;
		justify-content: space-between;
		padding-left: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);
		padding-right: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);
		transform: translateY(0px);

		.backdropfilter & {
			backdrop-filter: blur(10px);
			background-color: fade(@palette-primary-orange, 90%);
		}
		
		.site-header__logo {
			margin-right: auto;
		}

		.site-nav {
			margin-left: auto;
		}
	});

	.mq--lg({
		flex-wrap: wrap;
		min-height: @l-site-nav__item--parent-height--lg;

		.site-nav {
			order: 1;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
		}

		.site-nav__list {
			margin-top: @l-site-header--padding-y--lg;
			border-top: 1px solid fade(#fff, 25%);
			text-align: center;
		}
	});

	.mq--xl({
		min-height: @l-site-nav__item--parent-height--xl;

		.site-header__logo {
			svg {
				width: 100%;
			}
		}

		.site-header__donations {
			order: 1;
		}
		
		.site-header__socials {
			order: 2;
		}
	});
}

.site-header__logo {
	position: relative;
	line-height: 0; // Crush this stuff!
	@small--aspect-ratio: (24 / 300); // -> @site-logo--small.svg

	.mq--md--down({
		display: block;
		z-index: @zindex--site-header-contents--md--down;

		&:after {
			position: absolute;
			top: 0;
			bottom: 0;
			content: "";
			background-image: url('../img/site-logo--small.svg');
			background-size: contain;
			background-position: left center;
			background-repeat: no-repeat;
			max-width: 20rem;
		}

		svg {
			display: none;
		}
	});

	.mq--sm--down({
		left: @l-site-header--height--sm;
		height: @l-site-nav__item--parent-height--sm;
		margin-right: (1 / @l-site--col-total * 100%);
		@gutter: (@l-site-header--height--sm * 1.25);
		max-width: ~"calc(100vw - @{gutter})";

		&:after {
			@indent: unit(1 / 3, rem);
			right: @indent;
			left: -@indent;
		}
	});

	.mq--md({
		left: @l-site-header--height--md;
		height: @l-site-nav__item--parent-height--md;
		width: (1 / 3 * 100%);
		min-width: 17.75rem;

		&:after {
			@indent: 0.625rem;
			right: @indent;
			left: -@indent;
		}
	});

	.mq--lg--up({
		svg {
			display: inline-block;
			vertical-align: middle;
			height: unit(4 / 3, rem);
			width: auto;
		}
	});

	.mq--lg({
		height: @l-site-nav__item--parent-height--lg;
		line-height: @l-site-nav__item--parent-height--lg;
	});

	.mq--xl({
		height: @l-site-nav__item--parent-height--xl;
		line-height: @l-site-nav__item--parent-height--xl;
	});
}

.site-header__donations {
	.t-fout-mask();
	font-family: @font-family-secondary;
	color: #fff;

	.site-header__donate-link {
		display: inline-block;
		line-height: 1;
		padding: 0.75em 0.875em;
		border: 2px solid @color-brand-yellow;
		background-color: @palette-primary-orange;
		border-radius: @border-radius;
		color: #fff;
		transition+: border 500ms @timing-function--ease-out--expo;
		transition+: box-shadow 500ms @timing-function--ease-out--expo;
		
		.item-label {
			position: relative;
			top: 1px; // Fudge the vertical alignment
		}

		&:hover,
		&:focus {
			color: @palette-primary-off-black;
			background-color: @color-brand-yellow;
			border-color: @color-brand-yellow;
			transition-duration: 250ms;
		}

		&:focus {}
	}

	// Breakpoint small
	.mq--md--down({
		z-index: @zindex--site-header-contents--md--down;
		position: absolute;
		right: (@l-page-container--margin-x--col--sm--up / @l-site--col-total * 100%);

		.site-header__donate-link {
			padding-top: 0;
			padding-bottom: 0;
		}
	});

	.mq--xs({
		// Hide for xs breakpoint
		display: none;
	});

	.mq--sm--down({
		font-size: (0.75rem / @font-size--factor--sm);
		top: @l-site-header--padding-y--sm;

		.site-header__donate-link {
			height: @l-site-nav__item--parent-height--sm;
			line-height: unit(@l-site-nav__item--parent-height--sm);
		}
	});

	.mq--md({
		font-size: 0.875rem;
		top: @l-site-header--padding-y--md;

		.site-header__donate-link {
			height: @l-site-nav__item--parent-height--md;
			line-height: unit(@l-site-nav__item--parent-height--md);
		}
		
		// Add some space between social icons and button
		+ .site-header__socials {
			padding-left: 1.5rem;
		}
	});
	
	.mq--lg--up({
		// Add some space between social icons and button
		+ .site-header__socials {
			padding-left: 1.5rem;
		}
	});

	// Breakpoint large
	.mq--lg({
		font-size: @l-site-header-font-size--lg;
		height: @l-site-nav__item--parent-height--lg;
		line-height: @l-site-nav__item--parent-height--lg;
		
		// IE10 fix
		margin-left: auto;
	});

	.mq--xl({ // 14px
		font-size:  @l-site-header-font-size--xl;
		height: @l-site-nav__item--parent-height--xl;
		line-height: @l-site-nav__item--parent-height--xl;
	});
}

.site-header__socials {
	.mq--sm--down({
		display: none;
	});
	
	.mq--md--down({
		z-index: @zindex--site-header-contents--md--down;
	});
	
	.social-service-icon-list {
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
	}
}
