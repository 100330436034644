.media-caption {
	@font-size--base: 0.75rem;
	.t-fout-mask();
	display: block;
	margin-top: 0.75em;
	margin-bottom:	0.75em;
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
	font-size: @font-size--base;
	line-height: (5 / 3);

	a {
		.t-link-underline-scaffolding();
	}

	.media-caption--theme();

	// Placed after theme to override hover
	a {
		&:hover,
		&:focus {
			.link-underline--theme--hover(@palette-primary-orange, 50%);
		}
	}

	.mq--xs({
		font-size: (0.625rem / @font-size--factor--sm);
	});

	.mq--sm({
		font-size: (0.6875rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});
}

.media-caption__description {
	font-weight: bold;
	font-family: @font-family-secondary;

	&:after {
		content: "\00a0";
	}
}

.media-caption__credit {
	display: inline-block;
}

.media-caption__credit-type {
	text-transform: uppercase;
	letter-spacing: 0.25em;
	font-size: (5 / 6 * 100%);
}
