.content-item--columns {

}

.content-item--columns__column-group {
	.clearfix();

	margin-left: @l-column-group--margin-x--pct--lg;
	margin-right: @l-column-group--margin-x--pct--lg;

	.mq--xs({
		margin-left: @l-column-group--margin-x--pct--xs;
		margin-right: @l-column-group--margin-x--pct--xs;
	});

	.mq--sm({
		margin-left: @l-column-group--margin-x--pct--sm;
		margin-right: @l-column-group--margin-x--pct--sm;
	});

	.mq--md({
		margin-left: @l-column-group--margin-x--pct--md;
		margin-right: @l-column-group--margin-x--pct--md;
	});

	.mq--xl({
		margin-left: @l-column-group--margin-x--pct--xl;
		margin-right: @l-column-group--margin-x--pct--xl;
	});

	margin-top: -1rem;
	margin-bottom: -1rem;
}

.content-item--columns__column {
	width: 50%;
	float: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: @l-column--padding-x--pct--lg;
	padding-right: @l-column--padding-x--pct--lg;

	.mq--xs({
		padding-left: @l-column--padding-x--pct--xs;
		padding-right: @l-column--padding-x--pct--xs;
	});

	.mq--sm({
		padding-left: @l-column--padding-x--pct--sm;
		padding-right: @l-column--padding-x--pct--sm;
	});

	.mq--md({
		padding-left: @l-column--padding-x--pct--md;
		padding-right: @l-column--padding-x--pct--md;
	});

	.mq--xl({
		padding-left: @l-column--padding-x--pct--xl;
		padding-right: @l-column--padding-x--pct--xl;
	});

	&:nth-child(odd) {
		clear: left;
	}

	.mq--lg--up({
		&:nth-last-child(3n):first-child,
		&:nth-last-child(3n):first-child ~ & {
			width: (1 / 3 * 100%);

			&:nth-child(odd) {
				clear: none;
			}

			&:nth-last-child(3n) {
				clear: left;
			}
		}
	});

	// RESETS :(
	.mq--sm--down({
		float: none;
		width: auto;
		padding-top: 0;
		padding-bottom: 0;

		& + & {
			margin-top: 2rem;
		}
	});

	.content-item__text-limit {
		width: auto;
	}

	.content-item--media {
		margin-left: auto;
		margin-right: auto;
	}

	.media-caption {
		margin-top: 1em;
	}

	.media-figure--image  {
		img {
			width: 100%;
		}
	}
}
