.content-item--quotation {
	color: @palette-secondary-blue;

	&:not(.content-item--breakout) {
		margin-top: @l-content-item--margin-y--lg;
		margin-bottom: @l-content-item--margin-y--lg;

		.mq--sm--down({
			margin-top: @l-content-item--margin-y--sm;
			margin-bottom: @l-content-item--margin-y--sm;
		});
	}
}

.content-item--quotation__limit {
	margin-left: auto;
	margin-right: auto;
	width: (2 / 3 * 100%);

	.mq--xs({
		width: 87.5%;
	});

	.mq--sm({
		width: 75%;
	});
}

.content-item--quotation__blockquote {
	// border-top: 1px solid lighten(@palette-secondary-blue, (1 / 3 * 100%));
	margin: 0;
	text-align: center;
}

.content-item--quotation__body {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
	font-style: italic;
	font-size: 112.5%;

	.content-item--breakout & {
		font-size: 100%;
	}

	a {
		.t-link-underline-scaffolding(1px);
		.link-underline--theme(@palette-secondary-blue, @palette-secondary-blue);
	}
}

.content-item--quotation__footer {
	.t-fout-mask();
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
	font-size: 0.75rem;
	font-family: @font-family-secondary;
}

.content-item--quotation__citation {
	font-style: normal;
	font-weight: bold;

	a {
		.t-link-underline-scaffolding();
		.link-underline--theme(@palette-secondary-blue, @palette-secondary-blue);
	}

	&:before {
		content: "—";
	}
}
