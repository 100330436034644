// Typography
// --------------------------------------------------

.t-fout-mask() {
	html.wf-loading & {
		visibility: hidden;
	}
}

.t-primary-line-height() {
	.wf-itcstoneserifweb-n4-active &,
	.wf-itcstoneserifweb-i4-active & {
		line-height: @line-height--primary--base;

		.mq--md--down({
			line-height: @line-height--primary--md;
		});

		.mq--xl({
			line-height: @line-height--primary--xl;
		});
	}
}

.t-base() {
	font: @font-size-base/@line-height-base @font-family-base;
	text-align: left;
	color: @color-body;
}

.t-section-heading() {
	@font-size--base: 1.75rem;
	.t-fout-mask();
	margin-top: 1em;
	margin-bottom: 1em;
	font-weight: normal;
	font-size: @font-size--base;
	line-height: 1.25;

	.mq--sm--down({
		font-size: 1.5rem;
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});
}

.t-style-spotlight() {
	&.spotlight {
		margin-top: 2em;
		margin-bottom: 2em;
		text-align: center;
		font-size: 125%;
	}
}

.t-link-underline-scaffolding(@border-width: 1px) {
	border-bottom: @border-width solid;
	transition: 500ms @timing-function--ease-out--expo;
	transition-property: color, border-color;

	&:hover {
		transition-duration: 250ms;
	}
}
