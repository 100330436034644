.content-item--carousel {
	.media-carousel__items {
		&.flickity-enabled {
			.media-carousel__item {
				background-color: #fff;

				.media-figure__body {
					opacity: (1 / 3);
					transition: opacity 500ms @timing-function--ease-out--expo;
					will-change: opacity;
				}

				&.is-selected {
					.media-figure__body {
						opacity: 1;
					}
				}
			}
		}
	}

	.media-carousel__item {
		height: 100%;
		// max-width: 100%; // Disabled so that wide carousel items aren’t forced down to a 100% width
		overflow: hidden;
	}
}

.content-item__carousel-limit {
	.l-carousel-x-limit();
	overflow: hidden;

	.flickity-viewport {
		overflow: visible;
	}

	// Same as `.content-item__media-limit`
	.media-figure__caption {
		margin-left: auto;
		margin-right: auto;

		.mq--lg({
			margin-left: @l-media-caption--indent--pct--lg;
			margin-right: @l-media-caption--indent--pct--lg;
		});

		.mq--xl({
			margin-left: @l-media-caption--indent--pct--xl;
			margin-right: @l-media-caption--indent--pct--xl;
		});
	}
}
