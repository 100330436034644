._faux-block-link {
	position: relative;
}

._faux-block-link__overlay {
	display: block;
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background: rgba(0, 0, 0, 0);
	cursor: pointer;
	.hide-text();
	.prevent-user-select();
}
