.social-service-icon-list {
	margin: -0.375rem -0.75rem;
	padding: 0;
	list-style: none;
	
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	
	.social-service-icon-list__item {
		a {
			// HACK to clobber!
			border: 0 !important;
		}
	}
}

.social-service-icon-list__item {
	padding: 0.375rem 0.75rem;
	line-height: 0;
	
	a {
		&:hover,
		&:focus {
			.social-service-icon-list__item__icon {
				overflow: visible;
				
				path,
				g {
					transition-duration: 250ms;
					fill: @palette-primary-yellow;
				}
			}
		}
	}
}

.social-service-icon-list__item__icon {
	path,
	g {
		transition: all 500ms @timing-function--ease-out--expo;
	}
}