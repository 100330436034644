.content-item {
	& + & {
		margin-top: @l-content-item--margin-y--lg;

		.mq--sm--down({
			margin-top: @l-content-item--margin-y--sm;
		});

		&.content-item--columns {
			margin-top: (@l-content-item--margin-y--lg * 0.5);

			.mq--sm--down({
				margin-top: (@l-content-item--margin-y--sm * 0.5);
			});
		}
	}

	/*
	& + &.content-item--text--has-title {
		margin-top: (@l-content-item--margin-y--lg * 1.25);

		.mq--sm--down({
			margin-top: (@l-content-item--margin-y--sm * 1.25);
		});
	}
	*/

	&.content-item--text--has-title.content-item--text--has-no-body + & {
		margin-top: (@l-content-item--margin-y--lg * 0.375);

		.mq--sm--down({
			margin-top: (@l-content-item--margin-y--sm * 0.375);
		});
	}
}

.content-item__text-limit {
	.l-text-x-limit();
}
