.site-grid {
  display: flex;
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%; // IE10

  pointer-events: none;
  opacity: 0.25;

  .site-grid__col {
    flex: 1;
    background-color: #999;
    margin-left: @l-site--col-gutter;
    margin-right: @l-site--col-gutter;

    .mq--sm--down({
      &:nth-child(even) {
        display: none;
      }
    });
  }
}
