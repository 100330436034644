@page-index--font-size: 0.875rem;
@page-index--font-size--xl: (0.9375rem / @font-size--factor--xl);
@page-index__item--font-size: 0.625rem;
@page-index__item--font-size--xl: 0.625rem;

.page-index {
	margin: 1rem auto;
	font-family: @font-family-secondary;
	font-size: @page-index--font-size;

	.mq--sm--down({
		// Not enough space
		// margin-bottom: @l-page-section--margin-y--sm;
	});

	.mq--lg--down({
		margin-bottom: @l-page-section--margin-y--lg;
	});

	.l-text-x-limit();

	.mq--xl({
		width: auto; // Overrite `.l-text-x-limit()`
		font-size: @page-index--font-size--xl;
	});
}

.page-index__list {
	counter-reset: page-index__list;
	margin: 0;
	padding: 0;
	list-style: none;
}

.page-index__item {
	position: relative;
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;
	.t-fout-mask();

	&:before {
		top: 0;
		right: 100%;
		text-align: right;
		font-size: @page-index__item--font-size;
		line-height: unit(@page-index--font-size * @line-height-base / @page-index__item--font-size);
		color: @color-caption;
		counter-increment: page-index__list;
		content: counter(page-index__list, decimal-leading-zero);
		white-space: nowrap;

		.mq--xs({
			padding-right: 0.5em;
		});

		.mq--sm--up({
			position: absolute;
			padding-right: @l-site--col-gutter;
		});

		.mq--xl({
			line-height: unit(@page-index--font-size--xl * @line-height-base / @page-index__item--font-size--xl);
		});
	}

	& + & {
		margin-top: 1em;
	}
}

.page-index__item__label {
	.t-link-underline-scaffolding(1px);
	.link-underline--theme(@palette-primary-off-black, @palette-primary-orange);
}
