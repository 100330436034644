.page-hero {
	position: relative;
	background-color: @palette-primary-orange;

	+ .page-container__faux-top-lip {
		@aspect: (@l-page-hero--aspect-ratio / 2 * 100%);
		@offset: (@aspect * @l-page-hero--col--xs / @l-page-container--col--xs);
		margin-top: -@offset;
		padding-top: @offset;

		.mq--sm--up({
			@offset: (@aspect * @l-page-hero--col--sm--up / @l-page-container--col--sm--up);
			margin-top: -@offset;
			padding-top: @offset;
		});
	}
}

.page-hero__overlay {
	position: absolute;
	z-index: @zindex--page-hero__overlay;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-left: auto;
	margin-right: auto;
	@svg: ~'<svg width="300" height="120" viewBox="0 0 300 120" xmlns="http://www.w3.org/2000/svg"><defs><radialGradient cy="66.667%" fx="50%" fy="66.667%" r="60%" id="a"><stop stop-color="#231F20" offset="0%"/><stop stop-color="#231F20" stop-opacity=".8" offset="20%"/><stop stop-color="#231F20" stop-opacity="0" offset="100%"/></radialGradient></defs><path d="M300 0v120H0V0h300z" fill="url(#a)" fill-rule="evenodd" fill-opacity=".40"/></svg>';

	background-image: ~"url('data:image/svg+xml;charset=UTF-8," escape(@svg) ~"')";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	mix-blend-mode: multiply;

	// Firefox OSX fix for mix-blend-mode
	// https://medium.com/@maykelesser/how-to-fix-osx-firefox-mix-blend-mode-bug-6b3548e9e546#.xra4xbk4n
	border: 1px solid transparent;

	.mq--xs({
		width: @l-page-hero--pct--xs;
	});

	.mq--sm--up({
		width: @l-page-hero--pct--sm--up;
	});
}

.page-hero__figure {
	position: relative;
	z-index: @zindex--page-hero__figure;
	margin-left: auto;
	margin-right: auto;
	background-color: @palette-primary-off-black;

	.mq--xs({
		width: @l-page-hero--pct--xs;
	});

	.mq--sm--up({
		width: @l-page-hero--pct--sm--up;
	});

	.media-figure__body {
		position: relative;
		padding-top: (@l-page-hero--aspect-ratio * 100%);
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}

	.media-caption {
		display: none;
	}
}
