.content-item--text-small {
	.content-item__title {
		@font-size--base: 1.125rem;
		.t-fout-mask();
		margin: 1rem auto 0.75rem;
		padding-left: @l-site--col-gutter;
		padding-right: @l-site--col-gutter;
		color: @color-heading;
		font-weight: normal;
		font-size: @font-size--base;
		line-height: (4 / 3);

		.mq--sm--down({
			font-size: (@font-size--base / @font-size--factor--sm);
		});

		.mq--xl({
			font-size: (@font-size--base / @font-size--factor--xl);
		});
	}
}
