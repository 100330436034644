.content-item--media {
	clear: both;
}

.content-item--media-image {
	&:not(.content-item--breakout) {
		.media-figure--image img {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.content-item__media-limit {
	margin-left: auto;
	margin-right: auto;
	.l-media-x-limit();

	.media-figure__caption {
		margin-left: auto;
		margin-right: auto;

		.mq--lg({
			margin-left: @l-media-caption--indent--pct--lg;
			margin-right: @l-media-caption--indent--pct--lg;
		});

		.mq--xl({
			margin-left: @l-media-caption--indent--pct--xl;
			margin-right: @l-media-caption--indent--pct--xl;
		});
	}
}

.content-item__media-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: -@l-media-grid--item--margin-y--lg;
	margin-bottom: -@l-media-grid--item--margin-y--lg;
	
	// margin-bottom: -1 * @l-content-item--margin-y--sm;

	.media-figure {
		margin: @l-media-grid--item--margin-y--lg 0;
		padding-left: @l-site--col-gutter;
		padding-right: @l-site--col-gutter;
		max-width: 100%;
		
		.mq--sm--down({
			flex-basis: 50%;
		});
		
		.mq--md({
			flex-basis: percentage(1 / 3);
		});
		
		.mq--lg--up({
			flex-basis: 25%;
		});
			
		.media-figure__body {
			position: relative;
			padding-top: 100%;
			
			img {
				box-sizing: border-box;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				
				&.-donotcrop {
					.mq--sm--down({
						padding: 1rem;
					});
					
					.mq--md({
						padding: 1rem;
					});
					
					.mq--lg--up({
						padding: 0.75rem;
					});
				}
			}
		}
		
		.media-figure__caption {
			text-align: center;
			
			// Reset indentation
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	.media-figure__placeholder {
		margin: 0;
		.mq--md--down({ margin: 0; });
	}
}