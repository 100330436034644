.page-container {
	background-color: #fff;
	margin-left: auto;
	margin-right: auto;

	.mq--xs({
		width: @l-page-container--pct--xs;
	});

	.mq--sm--up({
		width: @l-page-container--pct--sm--up;
	});
}

.page-container__faux-top-lip {
	position: relative;
	background-color: #fff;
}

.page-article--has-aside {}

.page-article--has-no-aside {}

.page-layout {
	.clearfix();
	position: relative;
	margin-top: @l-page-layout--margin-n;
	margin-bottom: @l-page-layout--margin-s;

	.page-section {
		&:first-of-type {
			.content-item:first-child {
				// Erase top margin, present on quotations
				margin-top: 0;
			}
		}

		&.page-section--themed {
			.mq--xs({
				&:last-child {
					margin-bottom: -@l-page-layout--margin-s;
				}
			});
		}
	}
}

.page-layout--has-aside {
	.mq--xl({
		.page-aside {
			float: left;

			&.page-aside--first-section-has-title {
				margin-top: unit(1 / 3, rem);
			}
		}

		.page-body {
			float: right;

			// We’re offsetting the (non-aside version of .page-layout)’s symmetrical padding so that we have equal gutters between the edges, aside and body elements
			padding-left: (@l-page-body--padding-x--pct--xl * @l-page-body--padding-e--factor);
			padding-right: (@l-page-body--padding-x--pct--xl * @l-page-body--padding-w--factor);
		}
	});
}

.page-aside,
.page-body {
	margin-left: auto;
	margin-right: auto;
	padding-left: @l-page-body--padding-x--pct--lg;
	padding-right: @l-page-body--padding-x--pct--lg;

	.mq--xs({
		padding-left: @l-page-body--padding-x--pct--xs;
		padding-right: @l-page-body--padding-x--pct--xs;
	});

	.mq--sm({
		padding-left: @l-page-body--padding-x--pct--sm;
		padding-right: @l-page-body--padding-x--pct--sm;
	});

	.mq--md({
		padding-left: @l-page-body--padding-x--pct--md;
		padding-right: @l-page-body--padding-x--pct--md;
	});
}

.page-aside {
	position: relative;
	z-index: @zindex--page-aside;

	.mq--xl({
		width: @l-page-aside--pct--xl;
		padding-right: (@l-page-body--padding-x--pct--xl * (2 / 3));
		padding-left: (@l-page-body--padding-x--pct--xl * (4 / 3));
	});

	.page-section {
		margin-top: 4rem;
	}
}

.page-body {
	.mq--xl({
		width: @l-page-body--pct--xl;
		padding-left: @l-page-body--padding-x--pct--xl;
		padding-right: @l-page-body--padding-x--pct--xl;
	});
}

.page-title {
	@font-size--base: 0.8125rem;
	.t-fout-mask();
	margin: 2em auto;
	font-size: @font-size--base;
	line-height: 1.25;
	font-weight: bold;
	font-family: @font-family-secondary;
	text-transform: uppercase;
	letter-spacing: 0.25em;
	color: @color-heading;

	.mq--sm--down({
		font-size: (0.625rem / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});
}

.page-byline {
	.t-fout-mask();
	margin: 1rem auto;
	font-weight: normal;
	line-height: 1;
	color: @color-heading;
	font-size: 3rem;

	.mq--xs({
		font-size: 1.5rem;
	});

	.mq--sm({
		font-size: 1.75rem;
	});

	.mq--md({
		font-size: 2rem;
	});
}
