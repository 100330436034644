.home-carousel {
	position: relative;
	background-color: @palette-primary-orange;

	> ._context-spy {
		font-family: 'regular';

		.mq--xl({
			font-family: 'large';
		});
	}

	.page-container__faux-top-lip {
		background-color: #fff;
		margin-top: -(6 / @l-page-container--col--sm--up * 100%);
		padding-top: (6 / @l-page-container--col--sm--up * 100%);
	}
}

.home-carousel__primary-carousel {
	position: relative;
	margin-left: auto;
	margin-right: auto;

	.mq--xs({
		width: @l-page-hero--pct--xs;
	});

	.mq--sm--up--lg({
		width: @l-page-hero--pct--sm--up;
	});

	.mq--xl({
		margin-left: (@l-home-hero--margin-e--col--lg--up / @l-page-container--col--sm--up * 100%);
		width: (@l-home-hero--col--lg--up / @l-page-container--col--sm--up * 100%);
	});
}

.home-carousel__overflow-carousel {
	position: absolute;
	right: -(0.5 / @l-page-container--col--sm--up * 100%);
	top: 50%;
	@width: 5;
	width: (@width / @l-page-container--col--sm--up * 100%);
	margin-top: -((@width / 3) / @l-page-container--col--sm--up * 100%);

	-webkit-user-drag: none;
	.prevent-user-select();
}

.home-carousel__primary-carousel,
.home-carousel__overflow-carousel {
	z-index: @zindex--home-carousel;
}

// Carousel Item
// --------------------------------------------------
.home-carousel__item {
	width: 100%;
	padding-top: (@l-carousel--aspect-ratio * 100%);
	color: #fff;
}

.home-carousel__item__figure {
	position: absolute;
	z-index: @zindex--home-carousel__figure;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.home-carousel__item__overlay {
	position: absolute;
	z-index: @zindex--home-carousel__item__overlay;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;

	// Modified home-carousel__fade.svg
	@svg: '<svg width="300" height="200" viewBox="0 0 300 200" xmlns="http://www.w3.org/2000/svg"><defs><radialGradient cx="33.333%" fx="33.333%" fy="50%" r="50%" id="a"><stop stop-color="#231F20" offset="0%"/><stop stop-color="#231F20" stop-opacity=".8" offset="20%"/><stop stop-color="#231F20" stop-opacity="0" offset="100%"/></radialGradient></defs><path d="M0 0h300v200H0V0z" fill="url(#a)" opacity=".333" fill-rule="evenodd"/></svg>';

	background-image: ~"url('data:image/svg+xml;charset=UTF-8," escape(@svg) ~"')";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	mix-blend-mode: multiply;

	// Firefox OSX fix for mix-blend-mode
	// https://medium.com/@maykelesser/how-to-fix-osx-firefox-mix-blend-mode-bug-6b3548e9e546#.xra4xbk4n
	border: 1px solid transparent;
}

.home-carousel__item__body {
	position: absolute;
	z-index: @zindex--home-carousel__item__body;
	top: 0;
	right: 0;
	left: (2 / 18 * 100%);
	.prevent-user-select();

	.flexbox & {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: center;
		justify-content: center;

		// Flexbox needs a min height, rather than an absolute unit
		// So we set a min-height
		// And remove the bottom 0 limit
		height: 100%;
		min-height: 100%;
		bottom: auto;

		> * {
			// Disabled to fix IE
			// flex: 0;
		}
	}

	.no-flexbox & {
		height: 100%;
		bottom: 0;
	}
}

.home-carousel__item__title {
	@font-size--base: 3rem;
	.t-fout-mask();
	margin: 0;
	margin-top: -1em;
	margin-right: (6 / 16 * 100%);
	color: currentColor;
	font-size: 3rem;
	line-height: 1.125;
	font-weight: normal;
	text-shadow: 0 0 1em fade(@palette-primary-off-black, (1 / 3 * 100%)), 0 0 0.125em fade(@palette-primary-off-black, 40%);

	.mq--xs({
		font-size: 2rem;
		margin-right: (6 / 23 * 100%);
		margin-top: unit(-1 / 3, em);
	});

	.mq--sm({
		font-size: 2.25rem;
		margin-right: (6 / 22 * 100%);
		margin-top: -0.5em;
	});

	.mq--md({
		font-size: 2.5rem;
		margin-top: -0.75em;
	});

	+ .home-carousel__item__cta {
		margin-top: 2.5em;
	}
}

.home-carousel__item__cta {
	text-shadow: 0 0 1.25em @palette-primary-off-black;
	font-weight: bold;
	border-width: 2px;

	.mq--sm--down({
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		// Reset
		left: -(2 / 16 * 100%);
		margin-top: 0 !important; // HACK
		padding: 0;
		border: 0;

		> .call-to-action__label {
			.hide-text();
		}
	});

	.mq--md--up({
		background-color: fade(@palette-primary-off-black, 2.5%);
	});
}

// Navigation
// --------------------------------------------------
.home-carousel__navigation {
	position: absolute;
	z-index: @zindex--home-carousel__navigation;
	height: 45px;
	width: 120px;
	background-color: #fff;
	.prevent-user-select();

	.mq--sm--down({
		// We’re hiding it this way so we can still calculate some dimensions during HomeCarousel’s _buildPagination method
		visibility: hidden;
		top: 100%;
		left: 50%;
	});

	.mq--md--up--lg({
		bottom: 0;
		right: (1.5 / @l-page-container--col--sm--up * 100%);
	});

	.mq--xl({
		top: 50%;
		margin-top: -(0.375 / @l-page-container--col--sm--up * 100%);
		left: ((@l-home-hero--col--lg--up + @l-home-hero--margin-e--col--lg--up) / @l-page-container--col--sm--up * 100%);
	});
}

.home-carousel__navigation__button {
	position: absolute;
	top: 0;
	bottom: 0;
	display: block;
	border: 0;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	-webkit-appearance: none;
	width: 45px;
	text-align: center;
	overflow: hidden;
	line-height: 0;

	.no-inlinesvg & {
		svg {
			display: none !important;
		}

		&:after {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin-top: -1em;
			font-size: 1rem;
			line-height: 2em;
			text-align: center;
		}
	}

	svg {
		pointer-events: none;
		transform-origin: 50% 50%;

	path {
			stroke: currentColor;
		}
	}
}

.home-carousel__navigation__button--previous {
	right: 100%;
	background-color: @palette-primary-orange;
	color: #fff;

	.no-inlinesvg & {
		&:after {
			content: "\2190";
		}
	}
}

.home-carousel__navigation__button--next {
	right: 0;
	background-color: #fff;
	color: @palette-primary-orange;

	.no-inlinesvg & {
		&:after {
			content: "\2192";
		}
	}
}

.home-carousel__pagination {
	@font-size--base: 0.625rem;
	position: absolute;
	top: 50%;
	left: 0;
	right: 45px;
	right: 32px;
	bottom: 0;
	margin-top: -1em;
	text-align: center;
	font-family: @font-family-secondary;
	font-size: @font-size--base;
	line-height: 2em;
	letter-spacing: 0.125em;
	color: @color-heading;
	.t-fout-mask();

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	> span {
		display: inline-block;
	}

	.home-carousel__pagination-item--current {
		text-align: right;
		white-space: nowrap;
	}
}
