// new school

@vp-xl: ~"(min-width: @{bp-media--xl--min})";
@vp-lg: ~"(min-width: @{bp-media--lg--min}) and (max-width: @{bp-media--lg--max})";
@vp-md: ~"(min-width: @{bp-media--md--min}) and (max-width: @{bp-media--md--max})";
@vp-sm: ~"(min-width: @{bp-media--sm--min}) and (max-width: @{bp-media--sm--max})";
@vp-xs: ~"(max-width: @{bp-media--xs--max})";

@vp-lg-down: ~"(max-width: @{bp-media--lg--max})";
@vp-md-down: ~"(max-width: @{bp-media--md--max})";
@vp-sm-down: ~"(max-width: @{bp-media--sm--max})";

@vp-lg-up: ~"(min-width: @{bp-media--lg--min})";
@vp-md-up: ~"(min-width: @{bp-media--md--min})";
@vp-sm-up: ~"(min-width: @{bp-media--sm--min})";


// old school

.mq--xl(@rules) {
  @media (min-width: @bp-media--xl--min) {
    @rules();
  }
}

.mq--lg--down(@rules) {
  @media (max-width: @bp-media--lg--max) {
    @rules();
  }
}

.mq--lg--up(@rules) {
  @media (min-width: @bp-media--lg--min) {
    @rules();
  }
}

.mq--lg(@rules) {
  @media (min-width: @bp-media--lg--min) and (max-width: @bp-media--lg--max) {
    @rules();
  }
}

.mq--md--up--lg(@rules) {
  @media (min-width: @bp-media--md--min) and (max-width: @bp-media--lg--max) {
    @rules();
  }
}

.mq--md--down(@rules) {
  @media (max-width: @bp-media--md--max) {
    @rules();
  }
}

.mq--md--up(@rules) {
  @media (min-width: @bp-media--md--min) {
    @rules();
  }
}

.mq--md(@rules) {
  @media (min-width: @bp-media--md--min) and (max-width: @bp-media--md--max) {
    @rules();
  }
}

.mq--sm--up--lg(@rules) {
  @media (min-width: @bp-media--sm--min) and (max-width: @bp-media--lg--max) {
    @rules();
  }
}

.mq--sm--up--md(@rules) {
  @media (min-width: @bp-media--sm--min) and (max-width: @bp-media--md--max) {
    @rules();
  }
}

.mq--sm--down(@rules) {
  @media (max-width: @bp-media--sm--max) {
    @rules();
  }
}

.mq--sm--up(@rules) {
  @media (min-width: @bp-media--sm--min) {
    @rules();
  }
}

.mq--sm(@rules) {
  @media (min-width: @bp-media--sm--min) and (max-width: @bp-media--sm--max) {
    @rules();
  }
}

.mq--xs(@rules) {
  @media (max-width: @bp-media--xs--max) {
    @rules();
  }
}

.mq--retina(@rules) {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx) {
      @rules();
  }
}


