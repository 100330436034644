.site-map-nav {}

.site-map-nav__page-list {
	@font-size--base: 0.875rem;
	font-size: @font-size--base;

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	li {
		margin-top: 0.375em;
		margin-bottom: 0.375em;
	}

	.site-map-nav__section-list {
		@font-size--base: 0.8125rem;
		font-size: @font-size--base;

		.mq--sm--down({
			font-size: (@font-size--base / @font-size--factor--sm);
		});

		.mq--xl({
			font-size: (@font-size--base / @font-size--factor--xl);
		});
	}
}

.site-map-nav__section-list {
	margin: 0 @l-site--col-gutter;
	padding: 0;
	list-style: none;
	opacity: 0.9;
	transition: opacity 500ms @timing-function--ease-out--expo;
	-webkit-font-smoothing: antialiased;

	&:hover {
		opacity: 1;
		transition-duration: 250ms;
	}
}

.site-map-nav__item--page {
	> .site-map-nav__item--header {
		display: block;
		margin-left: @l-site--col-gutter;
		margin-right: @l-site--col-gutter;
		margin-bottom: 0.5em;
		line-height: (4 / 3);

		a {
			.t-link-underline-scaffolding(2px);
			.link-underline--theme(#fff, @color-brand-yellow, 0%);
		}
	}
}

.site-map-nav__item__label--page {
	font-weight: bold;
}
