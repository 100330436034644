.site-nav {
	.mq--sm--down({
		height: @l-site-header--height--sm;

		&:before {
			height: @l-site-header--height--sm;
		}

		.site-nav__list {
			padding-top: @l-site-header--height--sm;
			padding-left: @l-site-header--height--sm;
			// padding-bottom: (@l-site-header--height--sm * 1.5);
		}

		.site-nav__item:last-child {
			margin-bottom: @l-site-header--height--sm;
		}

		.site-nav__sub-nav {
			margin-left: -@l-site-header--height--sm;
			padding-left: @l-site-header--height--sm;
		}
	});

	.mq--md({
		height: @l-site-header--height--md;

		&:before {
			height: @l-site-header--height--md;
		}

		.site-nav__list {
			padding-top: @l-site-header--height--md;
			padding-left: @l-site-header--height--md;
			// padding-bottom: (@l-site-header--height--md * 1.5);
		}

		.site-nav__item:last-child {
			margin-bottom: @l-site-header--height--md;
		}

		.site-nav__sub-nav {
			margin-left: -@l-site-header--height--md;
			padding-left: @l-site-header--height--md;
		}
	});

	> .navburger {
		.mq--sm--down({
			width: @l-site-header--height--sm;
			height: @l-site-header--height--sm;
		});

		.mq--md({
			width: @l-site-header--height--md;
			height: @l-site-header--height--md;
		});
	}
}

.mq--md--down({
	.site-nav {
		z-index: @zindex--site-nav--md--down;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		background-color: @palette-primary-orange;
		// background-clip: content-box;
		overflow: hidden;
		transition: height 500ms @timing-function--ease-out--expo;

		// Vertical centering
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:before {
			z-index: 0; // Stack on top of `.site-nav__list`
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			background-color: fade(@palette-primary-orange, 95%);

			.backdropfilter & {
				backdrop-filter: blur(4px);
				background-color: fade(@palette-primary-orange, 90%);
			}
		}
	}

	.site-nav__menubutton--open {
		display: block;
	}

	.site-nav__list {
		z-index: -1; // Stack below `.site-nav:before` coverage
		position: relative;
		overflow: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		visibility: hidden;
		transition: visibility 0s linear 500ms; // On hide
	}

	.site-nav__item {
		padding-right: 3.5rem; // Sync .site-nav__sub-nav outdent too
		background-image: linear-gradient(to top, fade(#fff, 25%) 50%, fade(#fff, 0%) 50%);
		background-repeat: repeat-x;
		background-position: center bottom;
		background-size: 1px 2px;

		transform: translateX(100%);
		opacity: 0;
		// On hide
		transition: transform 250ms @timing-function--ease-out--expo 500ms, opacity 150ms @timing-function--ease-out--expo 0ms;

		& + & {
			margin-top: 0.75rem;
		}

		&.site-nav__item--has-sub-nav {
			touch-action: none;
			
			&:before {
				display: block;
				position: absolute;
				top: unit(2 / 3, rem);
				right: 1rem;
				content: "";
				width: 1.5rem;
				height: 1.5rem;
				border-right: 3px solid #fff;
				border-bottom: 3px solid #fff;
				transform: scale(0.5) rotate(45deg);
				transition: transform 0.5s @timing-function--ease-out--expo;
			}

			&.site-nav__item--sub-nav-open {
				&:before {
					transform: scale(-0.5) rotate(45deg);
					transition: transform 0.25s @timing-function--ease-out--expo;
				}
			}
		}
	}

	@underline-thickness: 3px;

	.site-nav__item-label {
		display: inline-block;
		padding-top: (0.75rem / @font-size--factor--sm);
		padding-bottom: (0.5rem / @font-size--factor--sm);
		font-size: 1.25rem;
		line-height: (4 / 3);
		color: #fff;
		text-decoration: none;
		border-bottom-width: @underline-thickness;
		border-bottom-color: transparent;
	}

	.site-nav__sub-nav {
		overflow: hidden;
		margin-right: -3.5rem; // counter `.site-nav__item` right padding
		display: none;
		background-color: #fff;
		border-top: @underline-thickness solid #fff;
		margin-top: -@underline-thickness;

		&.is-open {
			display: block;
		}

		&.is-closed {
			display: none;
		}
	}

	// Where the magic happens
	#site-nav__menustate:checked ~ .site-nav {
		height: 100%;
		transition: height (1s / 3) @timing-function--ease-in-out;

		#site-nav__menubutton--open {
			display: none;
		}

		#site-nav__menubutton--close {
			display: block;
		}

		> .navburger {
			position: fixed;
			.menu-hamburger--open();
		}

		.site-nav__list {
			visibility: visible;
			transition-delay: 250ms; // On show
		}

		.site-nav__item {
			@transition-delay: 300ms;
			@transition-stagger-delay: 50ms;
			transform: translateX(0);
			opacity: 1;
			transition-delay: @transition-delay; // On show

			// Stagger first 4 items with some cubic easing [0, 0.015625, 0.125, 0.421875, 1]
			// Successive items will fallback to the same uneased base `@transition-delay`
			&:first-child {
				transition-delay: (@transition-delay - @transition-stagger-delay);

				+ .site-nav__item {
					transition-delay: (@transition-delay - (@transition-stagger-delay * 0.421875));

					+ .site-nav__item {
						transition-delay: (@transition-delay - (@transition-stagger-delay * 0.125));

						+ .site-nav__item {
							transition-delay: (@transition-delay - (@transition-stagger-delay * 0.015625));
						}
					}
				}
			}
		}
	}
});

.site-nav__sub-nav {
	@media screen and @vp-md-down {
		padding-right: 2rem;
	}
}

.site-nav__sub-nav-group-list {
	@media screen and @vp-md-down {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin-left: -2rem;
		margin-right: -2rem;
		
		.site-nav__sub-nav-group {
			flex-basis: 50%;
			max-width: 50%;
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
	
	@media screen and @vp-xs {
		margin-left: -1rem;
		margin-right: -1rem;
		
		.site-nav__sub-nav-group {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}

.site-nav__sub-nav-group {
	@media screen and @vp-md-down {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

.site-nav__sub-nav-group-heading + .site-nav__sub-nav-group-item-list {
	@media screen and @vp-md-down {
		margin-top: 1rem;
	}
}

.site-nav__sub-nav-group-item-list {
	@media screen and @vp-md {
		font-size: 0.75rem;
	}
	
	@media screen and @vp-sm-down {
		font-size: (0.75rem / @font-size--factor--sm);
	}
}