.content-item--tab-set {
	clear: both;
	position: relative;

	> ._context-spy {
		font-family: 'regular';

		.mq--lg--up({
			font-family: 'large';
		});
	}
}

.content-item--tab-set__header {
	.mq--xs({
		margin-left: @l-tab-set-header--outdent--pct--xs;
		margin-right: @l-tab-set-header--outdent--pct--xs;
	});

	.mq--sm({
		margin-left: @l-tab-set-header--outdent--pct--sm;
		margin-right: @l-tab-set-header--outdent--pct--sm;
	});

	.mq--md({
		margin-left: @l-tab-set-header--outdent--pct--md;
		margin-right: @l-tab-set-header--outdent--pct--md;
	});

	.mq--lg({
		margin-left: @l-tab-set-header--outdent--pct--lg;
		margin-right: @l-tab-set-header--outdent--pct--lg;
	});

	.mq--xl({
		margin-left: @l-tab-set-header--outdent--pct--xl;
		margin-right: @l-tab-set-header--outdent--pct--xl;
	});
}

.content-item--tab-set__tabs {
	// We want to breakout to the limits of (padding-box) `.page-body`, so we're up against the orange border

	.mq--xs({
		margin-left: @l-tab-set-tabs--outdent--pct--xs;
		margin-right: @l-tab-set-tabs--outdent--pct--xs;

		.tab-set-item__text {
			padding-left: @l-page-body--padding-x--pct--xs;
			padding-right: @l-page-body--padding-x--pct--xs;
		}

		.tab-set-item__media {
			padding-left: percentage((@l-page-container--col--xs - @l-text--col--xs) / 2 / @l-page-container--col--xs);
		}
	});

	.mq--sm({
		margin-left: @l-tab-set-tabs--outdent--pct--sm;
		margin-right: @l-tab-set-tabs--outdent--pct--sm;

		.tab-set-item__text {
			padding-left: @l-page-body--padding-x--pct--sm;
			padding-right: @l-page-body--padding-x--pct--sm;
		}

		.tab-set-item__media {
			padding-left: percentage((@l-page-container--col--sm--up - @l-text--col--sm) / 2 / @l-page-container--col--sm--up);
		}
	});

	.mq--md({
		margin-left: @l-tab-set-tabs--outdent--pct--md;
		margin-right: @l-tab-set-tabs--outdent--pct--md;

		.tab-set-item__text {
			padding-left: @l-page-body--padding-x--pct--md;
			padding-right: @l-page-body--padding-x--pct--md;
		}

		.tab-set-item__media {
			padding-left: percentage((@l-page-container--col--sm--up - @l-text--col--md) / 2 / @l-page-container--col--sm--up);
		}
	});

	.mq--lg({
		margin-left: @l-tab-set-tabs--outdent--pct--lg;
		margin-right: @l-tab-set-tabs--outdent--pct--lg;
	});

	.mq--xl({
		margin-left: @l-tab-set-tabs--outdent--pct--xl;
		margin-right: @l-tab-set-tabs--outdent--pct--xl;
	});
}

.tab-set__nav {
	.prevent-user-select();
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	.mq--xs({
		@indent: percentage(@l-page-container--margin-x--col--xs / @l-site--col-total);
		padding-left: @indent;
		padding-right: @indent;
	});

	.mq--sm({
		@indent: percentage(@l-page-container--margin-x--col--sm--up / @l-site--col-total);
		padding-left: @indent;
		padding-right: @indent;
	});

	.mq--md({
		@indent: percentage(@l-page-container--margin-x--col--sm--up / @l-site--col-total);
		padding-left: @indent;
		padding-right: @indent;
	});

	.mq--lg({
		@indent: percentage(@l-page-container--margin-x--col--sm--up / @l-site--col-total);
		padding-left: @indent;
		padding-right: @indent;
	});

	.mq--xl({
		@indent: percentage(((@l-site--col-total - @l-page-body--content-box--col--xl) / 2) / @l-site--col-total);
		padding-left: @indent;
		padding-right: @indent;
	});

	&[data-pinny-state="pinned"] {
		position: fixed;
		z-index: @zindex--tab-set-header--pinned;
		left: 0;
		right: 0;
	}

	&[data-pinny-state="ghosting"] {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;

		.mq--xs({
			left: @l-tab-set-header--outdent--pct--xs;
			right: @l-tab-set-header--outdent--pct--xs;
		});

		.mq--sm({
			left: @l-tab-set-header--outdent--pct--sm;
			right: @l-tab-set-header--outdent--pct--sm;
		});

		.mq--md({
			left: @l-tab-set-header--outdent--pct--md;
			right: @l-tab-set-header--outdent--pct--md;
		});

		.mq--lg({
			left: @l-tab-set-header--outdent--pct--lg;
			right: @l-tab-set-header--outdent--pct--lg;
		});

		.mq--xl({
			left: @l-tab-set-header--outdent--pct--xl;
			right: @l-tab-set-header--outdent--pct--xl;
		});
	}
}

.tab-set__nav-item-list {
	margin: 0;
	padding: 0;
	list-style: none;

	// FLEXBOX
	display: flex;
}

.tab-set__nav-item {
	@font-size--base: 0.625rem;
	position: relative;
	text-align: center;
	font-size: @font-size--base;
	line-height: 1.2;
	font-family: @font-family-secondary;
	letter-spacing: 0.25em;
	text-transform: uppercase;
	font-weight: bold;

	.mq--sm--down({
		font-size: (@font-size--base / @font-size--factor--sm);
	});

	.mq--xl({
		font-size: (@font-size--base / @font-size--factor--xl);
	});

	// FLEXBOX
	flex: 1 0 auto;

	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 4px;
		content: "";
	}

	&.is-active {
		&:before {
			background-color: @palette-primary-orange;
		}
	}
}

.tab-set__nav-item-label {
	.t-fout-mask();
	display: block;
	padding: 1.5em 3em;
	color: @color-heading;
	background-color: #fff;
	white-space: nowrap;
}

.tab-set-item {
	position: relative;
	.tab-set-item--theme(@theme-default--bg--dark);

	.tab-set-item__media-item {
		@indent--col--lg: 0.5;
		@indent--col--xl: 1;
		@indent--pct--lg: (@indent--col--lg / @l-tab-set-item__media-item--col--lg * 100%);
		@indent--pct--xl: (@indent--col--xl / @l-tab-set-item__media-item--col--lg * 100%);

		// Some sneaky work where we hijack Masonry’s inline styles to determine whether the item is positioned on the left or right edge
		// Left
		&[style*="left: 0%"] {
			.media-caption {
				.mq--lg({
					margin-left: @indent--pct--lg;
				});

				.mq--xl({
					margin-left: @indent--pct--xl;
				});
			}
		}

		// Right
		&:not([style*="left: 0%"]) {
			.media-caption {
				.mq--lg({
					margin-right: @indent--pct--lg;
				});

				.mq--xl({
					margin-right: @indent--pct--xl;
				});
			}
		}

		// For `.media-figure--statistic` we want to indent the `.media-figure__body` as well
		&.media-figure--statistic {
			// Left
			&[style*="left: 0%"] {
				.media-figure__body {
					.mq--lg({
						margin-left: @indent--pct--lg;
					});

					.mq--xl({
						margin-left: @indent--pct--xl;
					});
				}
			}

			// Right
			&:not([style*="left: 0%"]) {
				.media-figure__body {
					.mq--lg({
						margin-right: @indent--pct--lg;
					});

					.mq--xl({
						margin-right: @indent--pct--xl;
					});
				}
			}
		}
	}

	.mq--lg--up({
		.tab-set-item__text	{
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			width: @l-tab-set-item__text--pct--lg; // IE10
			// max-width: @l-tab-set-item__text--x-limit;
		}

		.tab-set-item__text-inner {
			padding-top: (2 / @l-tab-set-item__text--col--lg * 100%);
			padding-bottom: (1.5 / @l-tab-set-item__text--col--lg * 100%);
		}

		.tab-set-item__media {
			padding-top: (2 / @l-page-container--col--sm--up * 100%);
			padding-bottom: (1.5 / @l-page-container--col--sm--up * 100%);
		}

		.tab-set-item__media-item {
			float: left;
		}

		.tab-set-item__gutter-sizer {
			box-sizing: content-box;
			width: @l-tab-set-item__text--pct--lg;
			padding-left: @l-tab-set-item__text-gutter--padding-x--pct--lg;
			padding-right: @l-tab-set-item__text-gutter--padding-x--pct--lg;
		}

		.tab-set-item__media-item {
			width: @l-tab-set-item__media-item--pct--lg;
		}
	});
}

.tab-set-item--has-media {
	.mq--lg--up({
		.tab-set-item__text {
			left: 0;
			right: 0;

			&[data-pinny-state="default"] {
				position: absolute;
				top: 0;
			}

			&[data-pinny-state="pinned"] {
				position: fixed;
				width: percentage(@l-tab-set-item__text--col--lg / @l-site--col-total);
			}

			&[data-pinny-state="ghosting"] {
				position: absolute;
			}
		}
	});
}

.tab-set-item__inner {

}

.tab-set-item__text {
	z-index: 1; // On top of adjacent media items
	position: relative;
	margin-left: auto;
	margin-right: auto;

	.mq--md--down({
		padding-top: 3rem;
		padding-bottom: 3rem;
	});

	.rich-text {
		margin-top: 1.5em;
		margin-bottom: -1em;

	+ .call-to-action {
			margin-top: 3.5em;
		}
	}
}

.tab-set-item__text-inner {
	padding-left: @l-site--col-gutter;
	padding-right: @l-site--col-gutter;

	.mq--md--down({
		margin-left: auto;
		margin-right: auto;
	});

	.mq--xs({
		width: @l-text--pct--xs;
	});

	.mq--sm({
		width: @l-text--pct--sm;
	});

	.mq--md({
		width: @l-text--pct--md;
	});
}

.tab-set__item__title {
	.t-section-heading();
	margin-top: 0;
}

.tab-set-item__media {
	.mq--xs({
		.media-figure {
			flex-basis: 75%;
		}
	});

	.mq--sm({
		.media-figure {
			flex-basis: percentage(2 / 3);
		}
	});

	.mq--md({
		.media-figure {
			flex-basis: 40%;
		}
	});

	.mq--md--down({
		display: flex;
		flex-direction: row;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		.media-figure {
			flex-shrink: 0;
			padding: 1rem @l-site--col-gutter 2rem;
		}

		.media-figure--statistic {
			.media-figure__body {
				margin: 1.5rem;
			}
		}

		.media-figure--image {
			img {
				max-height: 20rem;
				width: auto;
			}
		}
	});

	.mq--lg--up({
		.clearfix();

		> *:first-child {
			margin-top: 0;

			+ * {
				margin-top: 0;
			}
		}
	});
}

.tab-set-item__media-item {
	.mq--lg--up({
		margin-top: unit(5 / 3, em);
		margin-bottom: unit(5 / 3, em);

		.media-figure__body img {
			height: 100%;
			width: 100%;
		}
	});

}
