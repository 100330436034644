.content-item--text {
	.content-item__title {
		@font-size--base: 0.6875em;
		.t-fout-mask();
		margin: 1rem auto 0.875rem auto;
		padding-left: @l-site--col-gutter;
		padding-right: @l-site--col-gutter;
		color: @color-heading;
		font-family: @font-family-secondary;
		font-size: @font-size--base;
		line-height: 1.75;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		text-align: center;

		.mq--sm--down({
			font-size: (0.625em / @font-size--factor--sm);
		});

		.mq--xl({
			font-size: (@font-size--base / @font-size--factor--xl);
		});
	}
}

.content-item--text--has-title {
	// clear: both;
}

.content-item__text-limit {
	.rich-text {
		padding-left: @l-site--col-gutter;
		padding-right: @l-site--col-gutter;
	}
}
